import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  DateTimeFormatter,
  FormatterType,
  Icon,
  isBefore,
  Modal,
  Typography,
  urlReplace,
  useLoading,
  TeamLogoFormatter,
  TeamLogo,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { deletePurchaseOpportunity, getPurchaseEntries } from "../../api/RequesTixApi";
import PurchaseGameFormatter from "../../components/tables/PurchaseGameFormatter";
import UserBillingFormatter from "../../components/tables/UserBillingFormatter";
import RouteConstants from "../../constants/RouteConstants";
import { PurchaseOpportunityEntryRecordDTO } from "../../types/PurchaseOpportunity";
import { useNavigate } from "react-router-dom";

type EntryDataTable = DataTableDataType &
  PurchaseOpportunityEntryRecordDTO & {
    seriesTypeDesc?: string;
    processedDesc?: string;
  };

const EditTableFormatter = (props: FormatterType<EntryDataTable>) => {
  const { onClick, rowIndex, row } = props;
  const { cutoffDate } = row;
  return isBefore(new Date(), new Date(cutoffDate as string)) ? (
    <Icon
      iconName="fa-pencil-alt"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!!onClick) {
          onClick(rowIndex!, row);
        }
      }}
    />
  ) : null;
};

const DeleteTableFormatter = (props: FormatterType<EntryDataTable>) => {
  const { onClick, rowIndex, row } = props;
  const { cutoffDate } = row;
  return isBefore(new Date(), new Date(cutoffDate as string)) ? (
    <Icon
      iconName="fa-trash-alt"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!!onClick) {
          onClick(rowIndex!, row);
        }
      }}
    />
  ) : null;
};

type DeleteModalProps = {
  entry?: EntryDataTable;
  open: boolean;
  close: (deleted: boolean) => void;
};

const DeleteModel: React.FC<DeleteModalProps> = ({ entry, open, close }) => {
  const { setLoading } = useLoading();
  const deleteEntry = async () => {
    try {
      setLoading(true);
      await deletePurchaseOpportunity(entry.purchaseOpportunityId);
      close(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    close(false);
  };

  return (
    <Modal show={open}>
      <Modal.Header close={onClose}>Delete Entry</Modal.Header>
      <Modal.Body>
        {!!entry && (
          <Typography variant="body1">
            Are you sure you want to delete the purchase opportunity for <TeamLogo teamId={entry.teamId} size="40px" />{" "}
            {entry.seriesType.description} series?
          </Typography>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="me-1" onClick={deleteEntry} variant="primary">
            delete
          </Button>
          <Button onClick={onClose} variant="default">
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const MyPurchaseOppAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<EntryDataTable>>> = ({
  data,
  index,
  ...rest
}) => {
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(
      urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
        purchaseOpportunityId: data.purchaseOpportunityId,
        purchaseOpportunityEntryId: data.purchaseEntryIds[0],
      })
    );
  };

  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <EditTableFormatter value={data.purchaseOpportunityId} row={data} onClick={onEditClick} />
          <span className="mx-2">
            <TeamLogoFormatter value={data.teamId} />
          </span>
          {data.seriesTypeDesc}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Ballpark Email Address</AttributeHeader>
          <AttributeValue>{data.ballparkEmailAddress}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>User Billing</AttributeHeader>
          <AttributeValue>
            <UserBillingFormatter value={data.userBilling} />
          </AttributeValue>
        </div>
        <div>
          <AttributeHeader>Games (in priority order)</AttributeHeader>
          <AttributeValue>
            <PurchaseGameFormatter value={data.purchaseOpportunityGameIds} />
          </AttributeValue>
        </div>
        <div>
          <AttributeHeader>Cutoff Date</AttributeHeader>
          <AttributeValue>
            <DateTimeFormatter value={data.cutoffDate} />
          </AttributeValue>
        </div>
        <div>
          <AttributeHeader>Processed</AttributeHeader>
          <AttributeValue>{data.processed}</AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

const MyPurchaseOppTable: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<EntryDataTable[]>([]);
  const [deleteRecord, setDeleteRecord] = useState<EntryDataTable | undefined>(undefined);

  const Columns: DataTableColumn<EntryDataTable>[] = [
    {
      name: "",
      key: "purchaseOpportunityId",
      width: 40,
      onClick: (_rowIndex: number, row: EntryDataTable) => {
        navigate(
          urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
            purchaseOpportunityId: row.purchaseOpportunityId,
            purchaseOpportunityEntryId: row.purchaseEntryIds[0],
          })
        );
      },
      readonlyFormatter: EditTableFormatter,
    },
    {
      name: "",
      key: "purchaseOpportunityId",
      width: 40,
      onClick: (_rowIndex: number, row: EntryDataTable) => {
        setDeleteRecord(row);
      },
      readonlyFormatter: DeleteTableFormatter,
    },
    {
      name: "Team",
      key: "teamId",
      width: 60,
      readonlyFormatter: TeamLogoFormatter,
      cellClass: "justify-content-center",
    },
    {
      key: "year",
      name: "Year",
      width: 125,
    },
    {
      key: "seriesTypeDesc",
      name: "Series Type",
      width: 100,
    },
    {
      key: "ballparkEmailAddress",
      name: "Ballpark Email Address",
      width: 300,
    },
    {
      key: "userBilling",
      name: "User Billing",
      readonlyFormatter: UserBillingFormatter,
      width: 250,
    },
    {
      key: "purchaseOpportunityGameIds",
      name: "Home Games (in priority order)",
      readonlyFormatter: PurchaseGameFormatter,
      minWidth: 300,
    },
    {
      key: "status",
      name: "Status",
      width: 250,
    },
    {
      key: "cutoffDate",
      name: "Cutoff Date",
      readonlyFormatter: DateTimeFormatter,
      width: 200,
    },
  ];

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const res: PurchaseOpportunityEntryRecordDTO[] = await getPurchaseEntries();
      setEntries(
        res.map((e: PurchaseOpportunityEntryRecordDTO) => ({
          ...e,
          id: e.purchaseOpportunityId,
          seriesTypeDesc: e.seriesType?.description,
          processedDesc: JSON.stringify(!!e.processed),
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteModalClosed = (deleted: boolean) => {
    setDeleteRecord(undefined);
    if (deleted) {
      void fetchRequests();
    }
  };

  useEffect(() => {
    void fetchRequests();
  }, []);

  return (
    <>
      <DeleteModel close={deleteModalClosed} open={!!deleteRecord} entry={deleteRecord} />
      <DataTable
        statusTextOverride={(_selectedCount: number, totalCount: number) => `${totalCount} Entries`}
        data={entries}
        columns={Columns}
        accordion={MyPurchaseOppAccordion}
        loading={loading}
      />
    </>
  );
};

export default MyPurchaseOppTable;
