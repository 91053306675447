import React from "react";
import styled from "styled-components";
import { formatGameDate } from "../../util/TimeUtil";
import { MatchUp, StatsTeam, StatsTimeZone } from "best-common-react";

const Game = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5rem;
`;

const GameTime = styled.div`
  width: 10rem;
  font-size: 0.875rem;
`;

type SurroundingGameProps = {
  gameDate: string | Date;
  timeZone: StatsTimeZone;
  tbd: boolean;
  home: StatsTeam;
  away: StatsTeam;
  description: string;
};

const SurroundingGame: React.FC<SurroundingGameProps> = ({ gameDate, timeZone, tbd, home, away, description }) => {
  return (
    <Game>
      <GameTime>{formatGameDate(gameDate, timeZone, tbd)}</GameTime>
      <MatchUp home={home} away={away} description={description} />
    </Game>
  );
};

export default SurroundingGame;
