import { Button, Card, Loader, MatchUp, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  addEventBlackout,
  cancelDayOfGame,
  deleteEventBlackout,
  getDepartmentsByEvent,
  getEvent,
  removeDayOfCancellation,
} from "../../../api/RequesTixApi";
import EventAccess from "../../../components/events/EventAccess";
import { DepartmentByGameDTO } from "../../../types/Department";
import { GameDTO, GameTime } from "../../../types/Game";
import { useTimezone } from "../../../util/TimeUtil";

const EventDetails = styled.div.attrs({ className: "mx-3 mx-md-0 col-md-4 col-xl-3" })`
  display: flex;
  justify-content: space-between;
  min-width: 250px;
  padding: 1rem;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  flex-wrap: wrap;
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

type AdminEditEventUrlProps = {
  eventId: string;
};

const AdminEditEvent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { eventId } = useParams<AdminEditEventUrlProps>();
  const [event, setEvent] = useState<GameDTO>();
  const [eventTime, setEventTime] = useState<GameTime>();
  const [departments, setDepartments] = useState<DepartmentByGameDTO[]>([]);

  const getDepartments = async () => {
    try {
      setLoading(true);
      setDepartments([]);
      const res = await getDepartmentsByEvent(eventId);
      setDepartments(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    navigate(location.state.previousLocation);
  };

  const blackoutGame = async () => {
    try {
      setLoading(true);
      await addEventBlackout(eventId);
      setEvent({ ...event, blackedOut: true });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const removeBlackout = async () => {
    try {
      setLoading(true);
      await deleteEventBlackout(eventId);
      setEvent({ ...event, blackedOut: false });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancelDayOf = async () => {
    try {
      setLoading(true);
      await cancelDayOfGame(eventId);
      setEvent({ ...event, dayOfCancelled: true });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const removeDayOfCancel = async () => {
    try {
      setLoading(true);
      await removeDayOfCancellation(eventId);
      setEvent({ ...event, dayOfCancelled: false });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvent = async (eventId: string) => {
    try {
      setLoading(true);
      const res = await getEvent(eventId);
      setEvent(res);
      setEventTime(useTimezone(res.gameDate, res.venue.timeZone, res.status));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event?.season && event?.gameType) {
      void getDepartments();
    }
  }, [event]);

  useEffect(() => {
    if (!!eventId) {
      void fetchEvent(eventId);
    }
  }, [eventId]);

  return (
    <Card>
      <Card.Header>Event Details</Card.Header>
      <Card.Body>
        {!!event && eventTime && (
          <div className="d-flex mb-2">
            <Card>
              <Card.Body>
                <EventDetails>
                  <div>
                    <Header>{eventTime?.date}</Header>
                    <Description>
                      {eventTime?.day}
                      <span className="ms-2">{eventTime?.time}</span>
                    </Description>
                  </div>
                  <MatchUp
                    home={event.teams.home.team}
                    away={event.teams.away.team}
                    description={event.seriesDescription}
                  />
                </EventDetails>
              </Card.Body>
            </Card>
            <ActionContainer>
              {!event.blackedOut ? (
                <Button variant="default" onClick={blackoutGame}>
                  Blackout Game
                </Button>
              ) : (
                <Button variant="primary" onClick={removeBlackout}>
                  Remove Blackout
                </Button>
              )}
            </ActionContainer>
            <ActionContainer>
              {!event.dayOfCancelled ? (
                <Button variant="default" onClick={cancelDayOf}>
                  Cancel Today's Tickets
                </Button>
              ) : (
                <Button variant="primary" onClick={removeDayOfCancel}>
                  Remove Today's Tickets Cancellation
                </Button>
              )}
            </ActionContainer>
          </div>
        )}
        {!!event && !!departments.length ? (
          <EventAccess event={event} departments={departments} reload={getDepartments} />
        ) : (
          <div className="d-flex justify-content-center w-100 h-100">
            <Loader type="spinner" size="50px" />
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="default" onClick={close}>
            Close
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminEditEvent;
