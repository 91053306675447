import {
  ASC,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  FormatterType,
  Icon,
  IconLinkFormatter,
  NO_DIRECTION,
  Select,
  SortFilters,
  sortWithFunction,
  toLower,
  urlReplace,
  useTheme,
  ValueOpt,
} from "best-common-react";
import React, { memo, useEffect, useState } from "react";
import { OPEN } from "../../../constants/RequestTableConstants";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { RequestState } from "../../../types/RequestType";
import { TicketRequestDTO } from "../../../types/TicketRequest";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import AdminRequestAccordion from "./AdminRequestAccordion";
import AdminRequestCompleteIcon from "./AdminRequestCompleteIcon";
import AdminRequestExpansion from "./AdminRequestExpansion";
import { useLocation, useNavigate } from "react-router-dom";

const sort = (values: TicketRequestDTO[], sortFilters: SortFilters<TicketRequestDTO>, defaultKey = "submitted") => {
  if (sortFilters.direction === NO_DIRECTION) {
    return sortWithFunction(values, defaultKey as keyof TicketRequestDTO, 1, toLower);
  } else if (sortFilters.direction === ASC) {
    if (sortFilters.key === "status") {
      return sortWithFunction(values, "status", 1, (a) => a.requestStateId);
    } else {
      return sortWithFunction(values, sortFilters.key, 1, toLower);
    }
  } else {
    if (sortFilters.key === "status") {
      return sortWithFunction(values, "status", -1, (a) => a.requestStateId);
    } else {
      return sortWithFunction(values, sortFilters.key, -1, toLower);
    }
  }
};

type AdminRequestTableProps = {
  requests: TicketRequestDTO[];
  visible?: boolean;
  jumpToRequestId?: string | number;
};

const EditRequestFormatter = ({ value, ...rest }: FormatterType<TicketRequestDTO>) => (
  <IconLinkFormatter {...rest} value={urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: value })} />
);

const CommentFormatter = ({ value }: FormatterType<TicketRequestDTO>) => {
  return !!value?.length ? <Icon iconName="fa-comment" /> : null;
};
const StatusFormatter = ({ value, row }: FormatterType<TicketRequestDTO>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { requestStateOptions } = useDropdowns();
  const { requestId } = row;

  const changeValue = (val: ValueOpt<RequestState>) => {
    const newLocation = { ...location, search: removeRequestId(location) };
    navigate(
      `${urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: requestId })}?stateId=${val.value.requestStateId}`,
      {
        state: { previousLocation: newLocation },
      }
    );
  };

  return (
    <Select
      id={`status-select-${requestId}`}
      options={requestStateOptions}
      value={requestStateOptions.find(
        (opt: ValueOpt<RequestState>) => opt.value.requestStateId === value.requestStateId
      )}
      onChange={changeValue}
    />
  );
};

const StateFormatter = ({ value, row, className }: FormatterType<TicketRequestDTO>) => {
  const { status } = row;
  return status.requestStateId !== OPEN ? <AdminRequestCompleteIcon className={className} complete={!!value} /> : null;
};

const Columns: DataTableColumn<TicketRequestDTO>[] = [
  {
    key: "requestId",
    name: "",
    readonlyFormatter: EditRequestFormatter,
    icon: "fa-pencil-alt",
    cellClass: "align-items-center",
    width: 35,
  },
  {
    key: "status",
    name: "",
    readonlyFormatter: StatusFormatter,
    width: 150,
    sortable: true,
  },
  {
    key: "completed",
    name: "",
    cellClass: "justify-content-center",
    readonlyFormatter: StateFormatter,
    width: 50,
  },
  {
    key: "requester",
    name: "Requester",
    width: 150,
    sortable: true,
  },
  {
    key: "requesterJobTitle",
    name: "Job Title",
    width: 250,
    sortable: true,
  },
  {
    key: "recipient",
    name: "Recipient",
    width: 150,
    sortable: true,
  },
  {
    key: "department",
    name: "Department",
    width: 150,
    sortable: true,
  },
  {
    key: "quantity",
    name: "Qty",
    width: 60,
    cellClass: "justify-content-center",
    sortable: true,
  },
  {
    key: "ticketsSentDisplay",
    name: "Seats",
    minWidth: 150,
    sortable: true,
  },
  {
    key: "failedRequests",
    name: "Failed Requests",
    width: 125,
    sortable: true,
  },
  {
    key: "category",
    name: "Category",
    width: 100,
    sortable: true,
  },
  {
    key: "payBy",
    name: "Pay By",
    width: 100,
    sortable: true,
  },
  {
    key: "comments",
    name: "",
    width: 40,
    readonlyFormatter: CommentFormatter,
  },
  {
    key: "project",
    name: "Project",
    width: 250,
    sortable: true,
  },
  {
    key: "business",
    name: "Business",
    width: 200,
    sortable: true,
  },
  {
    key: "submitted",
    name: "Request Submitted",
    readonlyFormatter: DateTimeFormatter,
    width: 250,
    sortable: true,
  },
];

const AdminRequestTable = memo(({ requests, jumpToRequestId, visible }: AdminRequestTableProps) => {
  const { Theme } = useTheme();
  const [values, setValues] = useState<TicketRequestDTO[]>(requests);
  const [sortFilters, setSortFilters] = useState<SortFilters<TicketRequestDTO>>({ key: "submitted", direction: ASC });
  const [scrollToIndex, setScrollToIndex] = useState<number>(undefined);

  const rowStylesGetter = (index: number, value: TicketRequestDTO) => {
    return {
      background: getStatusColor(Theme, value.requestId, value.status.requestStateId, scrollToIndex === index),
    };
  };

  useEffect(() => {
    setValues(sort(requests, sortFilters));
  }, [requests, sortFilters]);

  useEffect(() => {
    if (!!jumpToRequestId) {
      const idx = requests.findIndex((r: TicketRequestDTO) => r.requestId === parseInt(jumpToRequestId as string));
      if (idx > -1) {
        setScrollToIndex(idx);
        setTimeout(() => {
          setScrollToIndex(undefined);
        }, 8000);
      }
    } else {
      setScrollToIndex(undefined);
    }
  }, [jumpToRequestId]);

  return (
    <DataTable
      data={values}
      columns={Columns}
      accordion={AdminRequestAccordion}
      sortFunction={(key, direction) => setSortFilters({ key, direction })}
      sortDirection={sortFilters.direction}
      sortColumn={sortFilters.key}
      loading={!visible}
      scrollToIndex={scrollToIndex}
      statusTextOverride={(_selected, total) => `${total} Requests`}
      expansion={AdminRequestExpansion}
      displayExpandAllButton={false}
      tableHeights={{
        noScroll: true,
      }}
      rowStylesGetter={rowStylesGetter}
      virtualized={false}
    />
  );
});

export default AdminRequestTable;
