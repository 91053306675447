import {
  Button,
  Card,
  CheckboxGroup,
  DateInput,
  Column,
  Input,
  Label,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
  Wysiwyg,
  StatsTeam,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPurchaseOpportunity, savePurchaseOpportunity } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { PurchaseOpportunityDTO, SeriesTypeDTO } from "../../../types/PurchaseOpportunity";

type PackageRouteParams = {
  id?: string;
};

const DefaultDisclosure = `<div>
<p>By submitting this form, I confirm that I am a full-time, benefits-eligible employee of Major League Baseball and that all information provided above is accurate to the best of my knowledge.</p>
<p>I hereby authorize MLB to charge my credit card above for the amount noted above if selected. I agree that I will pay for the purchase in accordance with the issuing bank cardholder agreement.</p>
<p>I understand any tickets received from any Baseball Entities, whether through purchase, contractual commitment, or otherwise, may not be offered for any public sale, either through any online resale outlet or any offline ticket reseller.</p><p>For purposes of the foregoing, “Baseball Entities” include the Office of the Commissioner of Baseball, MLB Advanced Media, L.P., Major League Baseball Properties, Inc., The MLB Network, LLC, the Major League Baseball Clubs, and each of their respective parent, subsidiary, affiliated or related entities.</p>
<p><b>Any tickets received from any Baseball Entities, whether through purchase, contractual commitment, or otherwise, may not be offered for any public sale, either through any online resale outlet or any offline ticket reseller.</b></p>
</div>`;

const validOpportunity = (opp: PurchaseOpportunityDTO): boolean =>
  !!opp.costDescription?.length &&
  !!opp.year &&
  !!opp.accessDate &&
  !!opp.cutoffDate &&
  opp.numOfWinners !== undefined &&
  !!opp.teamId &&
  opp.disclosure?.length > 0 &&
  opp.disclosure !== '<p class="editor-paragraph"><br></p>' &&
  !!opp.seriesType &&
  !!opp.gameNumbers?.length;

const AdminPurchaseOpportunityForm = () => {
  const { id } = useParams<PackageRouteParams>();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { teamsOptions, seriesTypeOptions, yearsOptions } = useDropdowns();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [newRecord, setNewRecord] = useState<boolean>(true);
  const [opportunity, setOpportunity] = useState<PurchaseOpportunityDTO>({});

  const fetchOpportunity = async (id: number | string) => {
    try {
      setLoading(true);
      const res: PurchaseOpportunityDTO = await getPurchaseOpportunity(id);
      setOpportunity({
        ...res,
        accessDate: new Date(res.accessDate as string),
        cutoffDate: new Date(res.cutoffDate as string),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveOpportunity = async () => {
    try {
      setLoading(true);
      await savePurchaseOpportunity(opportunity);
      navigate(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const onChange = (key: keyof PurchaseOpportunityDTO, value?: Date | string | number | number[] | SeriesTypeDTO) => {
    setOpportunity({ ...opportunity, [key]: value });
  };

  const changeSeriesType = (value?: SeriesTypeDTO) => {
    if (!!value) {
      const gameNumbers: number[] = [];
      for (let i = 1; i <= value.numOfGames; i++) {
        gameNumbers.push(i);
      }
      setOpportunity({ ...opportunity, seriesType: value, gameNumbers });
    } else {
      setOpportunity({ ...opportunity, seriesType: undefined, gameNumbers: [] });
    }
  };

  const addDefaultDisclosure = () => {
    onChange("disclosure", DefaultDisclosure);
  };

  const numOfGamesOptions: ValueOpt<number>[] = useMemo(() => {
    const options: ValueOpt<number>[] = [];
    if (!!opportunity.seriesType?.numOfGames) {
      for (let i = 1; i <= opportunity.seriesType.numOfGames; i++) {
        options.push({ label: `Home Game #${i.toString()}`, value: i });
      }
    }
    return options;
  }, [opportunity.seriesType]);

  useEffect(() => {
    const newForm = parseInt(id) === -1;
    setNewRecord(newForm);
  }, [id]);

  useEffect(() => {
    if (!newRecord) {
      void fetchOpportunity(id);
    } else {
    }
  }, [newRecord]);

  useEffect(() => {
    setCanSave(validOpportunity(opportunity));
  }, [opportunity]);

  return (
    <Card>
      <Card.Header>Purchase Opportunity</Card.Header>
      <Card.Body>
        <div className="row">
          <Column width={4}>
            <Select
              id="year-select"
              label="Year"
              placeholder="Select a year"
              options={yearsOptions}
              value={
                !!opportunity.year
                  ? yearsOptions.find((opt: ValueOpt<number>) => opt.value === opportunity.year)
                  : undefined
              }
              onChange={(value: ValueOpt<number> | undefined) => {
                if (!!value) {
                  onChange("year", value.value);
                } else {
                  onChange("year", undefined);
                }
              }}
              required
              gutterBottom
              disabled={!newRecord}
            />
          </Column>
          <Column width={4}>
            <Select
              id="team-select"
              label="Team"
              placeholder="Select a team"
              options={teamsOptions.slice(1)}
              value={
                !!opportunity.teamId
                  ? teamsOptions.find((opt: ValueOpt<StatsTeam>) => opt.value.id * 1 === parseInt(opportunity.teamId))
                  : undefined
              }
              onChange={(value: ValueOpt<StatsTeam> | undefined) => {
                if (!!value) {
                  onChange("teamId", value.value.id);
                } else {
                  onChange("teamId", undefined);
                }
              }}
              required
              gutterBottom
              disabled={!newRecord}
            />
          </Column>
          <Column width={4}>
            <Select
              id="series-type-select"
              label="Series Type"
              placeholder="Select a Series Type"
              options={seriesTypeOptions}
              value={
                !!opportunity.seriesType
                  ? seriesTypeOptions.find(
                      (opt: ValueOpt<SeriesTypeDTO>) => opt.value.seriesTypeId === opportunity.seriesType.seriesTypeId
                    )
                  : undefined
              }
              onChange={(value: ValueOpt<SeriesTypeDTO> | undefined) => {
                if (!!value) {
                  changeSeriesType(value.value);
                } else {
                  changeSeriesType(undefined);
                }
              }}
              required
              gutterBottom
              disabled={!newRecord}
            />
          </Column>
          <Column width={4}>
            <CheckboxGroup
              id="num-of-games-select"
              label="Games"
              options={numOfGamesOptions}
              values={opportunity.gameNumbers}
              onChange={(value: number[]) => {
                if (!!value) {
                  onChange("gameNumbers", value);
                } else {
                  onChange("gameNumbers", []);
                }
              }}
              required
              gutterBottom
              disabled={!newRecord}
            />
          </Column>
          <Column width={4}>
            <Input
              id="num-of-winners"
              type="number"
              label="Numer of Winners"
              placeholder="Number of winners"
              value={opportunity.numOfWinners}
              onChange={(value: string) => {
                if (!!value) {
                  onChange("numOfWinners", parseInt(value));
                } else {
                  onChange("numOfWinners", undefined);
                }
              }}
              required
              gutterBottom
            />
          </Column>
          <Column width={3}>
            <DateInput
              id="access-date"
              label="Access Date"
              placeholder="Access Date"
              value={opportunity.accessDate}
              onChange={(value: Date) => {
                onChange("accessDate", value);
              }}
              showTimeSelect
              withPortal
              required
              gutterBottom
            />
          </Column>
          <Column width={3}>
            <DateInput
              id="cutoff-date"
              label="Cutoff Date"
              placeholder="Cutoff Date"
              value={opportunity.cutoffDate}
              onChange={(value: Date) => {
                onChange("cutoffDate", value);
              }}
              showTimeSelect
              withPortal
              required
              gutterBottom
            />
          </Column>
          <Column width={3}>
            <Input
              id="cost-description"
              label="Cost Description"
              placeholder="Cost Description"
              required
              gutterBottom
              type="text"
              value={opportunity.costDescription}
              onChange={(value: string) => onChange("costDescription", value)}
            />
          </Column>
          <Column width={1}>
            <div className="d-flex align-items-center">
              <Label htmlFor="disclosure" required>
                Disclosure
              </Label>
              <Button variant="link" onClick={addDefaultDisclosure}>
                Add Default
              </Button>
            </div>
            <Wysiwyg
              id="disclosure"
              gutterBottom
              onChange={(value: string) => onChange("disclosure", value)}
              value={opportunity.disclosure}
            />
          </Column>
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="primary" disabled={!canSave} onClick={saveOpportunity}>
            Save
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminPurchaseOpportunityForm;
