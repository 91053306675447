import React from "react";
import styled from "styled-components";
import { BoxOfficeTicketDTO } from "../../types/Tickets";

type TicketStyleProps = {
  failed: boolean;
};

const Ticket = styled.div.attrs(() => ({
  className: "d-flex align-items-center mb-1",
}))<TicketStyleProps>`
  border: 1px solid
    ${(props) => (props.failed ? props.theme.alert.danger.border : props.theme.palette.outlineColorsOutlineVariant)};
  background: ${(props) =>
    props.failed ? props.theme.alert.danger.background : props.theme.palette.surfaceColorsSurfaceContainerLowest};
  color: ${(props) => (props.failed ? props.theme.alert.danger.color : props.theme.palette.onColorsOnPrimary)};
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.surfaceColorsSurfaceContainerLow};
`;

const TitleValue = ({ title, value, classes = "" }) => (
  <div className={`d-flex flex-column px-2 py-1 ${classes}`}>
    <div>{!!value ? value : <>&nbsp;</>}</div>
    <Title>{title}</Title>
  </div>
);

type TicketDisplayProps = {
  data: BoxOfficeTicketDTO;
};

const TicketDisplay = ({ data }: TicketDisplayProps) => {
  if (!data) {
    return <div />;
  } else if (data.isParking) {
    return (
      <Ticket key={data.ticketID} failed={data.failed}>
        <TitleValue value={data.section} title="Parkig Lot" />
        <TitleValue value={data.price} title="price" />
      </Ticket>
    );
  } else {
    return (
      <Ticket key={data.ticketID} failed={data.failed}>
        <TitleValue value={data.section} title="sec" />
        <TitleValue value={data.row} title="row" />
        <TitleValue value={data.seat} title="seat" />
        <TitleValue value={data.price} title="price" />
      </Ticket>
    );
  }
};

export default TicketDisplay;
