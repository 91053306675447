import {
  Button,
  Card,
  Column,
  Row,
  Section,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getDepartmentByYear, updateDepartment } from "../../../api/RequesTixApi";
import AccessCutoff from "../../../components/cutoffs/AccessCutoff";
import PersonalBusinessCutoff from "../../../components/cutoffs/PersonalBusinessCutoff";
import DepartmentAllotmentTable from "../../../components/department/DepartmentAllotmentTable";
import PersonalOnOff from "../../../components/requests/personal/PersonalOnOff";
import RouteConstants from "../../../constants/RouteConstants";
import { useAdminYear } from "../../../contexts/AdminYearContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { convertFromEventTypeDTO, convertToEventTypeDTO } from "../../../util/CutoffUtil";
import { useRouteParams } from "../../../util/RouteUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SPRING_TRAINING = "springTraining";
const REGULAR_SEASON = "regularSeason";
const ACCESS = "access";
const CUTOFF = "cutoff";

const AdminEditDepartments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { departmentId: departmentIdParam } = useParams();
  const departmentId = parseInt(departmentIdParam);
  const { year } = useRouteParams(location.search);
  const { setLoading } = useLoading();
  const { yearsOptions, getOptionByValue } = useDropdowns();
  const [selectedYear, setSelectedYear] = useAdminYear({
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear(),
  });
  const [department, setDepartment] = useState({
    departmentId: departmentId,
    departmentName: "",
    year: selectedYear.value,
    springTraining: null,
    regularSeason: null,
    springPersonalRequests: false,
    regularPersonalRequests: true,
  });

  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    if (year && yearsOptions && yearsOptions.length) {
      const defaultSelectedYear = getOptionByValue(yearsOptions, year * 1);
      if (defaultSelectedYear) {
        setSelectedYear(defaultSelectedYear);
      }
    }
  }, [year, yearsOptions]);

  useEffect(() => {
    setLoading(true);
    getDepartmentByYear(departmentId, selectedYear.value).then((data) => {
      setDepartment(data);
      setLoading(false);
    });
  }, [departmentId, selectedYear]);

  const changeAllotment = (allotment) => {
    setDepartment({ ...department, ...allotment });
    setHasEdited(true);
  };

  const changeAccessCutoff = (season, type, value) => {
    const newDept = { ...department };
    newDept[season][type] = value;
    setDepartment(newDept);
    setHasEdited(true);
  };

  const save = () => {
    setLoading(true);
    //@ts-ignore
    updateDepartment(department).then(() => {
      cancel();
    });
  };

  const onPersonalChange = (type, value) => {
    setDepartment({ ...department, [type]: value });
    setHasEdited(true);
  };

  const cancel = () => {
    navigate(RouteConstants.ADMIN.DEPARTMENTS);
  };

  return (
    <Card>
      <Card.Header>{department.departmentName}</Card.Header>
      <Card.Body>
        <Row>
          <Column width={4}>
            <Select
              id="year"
              label="Year"
              options={yearsOptions}
              value={selectedYear}
              onChange={(year: ValueOpt<number>) => setSelectedYear(year)}
              gutterBottom
            />
          </Column>
        </Row>
        {department && (
          <Section sectionKey="default" header="Default Request Allotment">
            <Row>
              <Column width={2}>
                <DepartmentAllotmentTable department={department} onChange={changeAllotment} />
              </Column>
            </Row>
          </Section>
        )}
        {department.springTraining && (
          <Section sectionKey="spring-training" className="mt-2" header="Spring Training Requests">
            <Row>
              <Column width={3}>
                <PersonalOnOff
                  value={department.springPersonalRequests}
                  onChange={(value) => onPersonalChange("springPersonalRequests", value)}
                />
              </Column>
            </Row>
            <Row className="mt-2">
              <Column width={2}>
                <Card>
                  <Card.Header>Event Access</Card.Header>
                  <Card.Body>
                    <AccessCutoff
                      override={department[SPRING_TRAINING][ACCESS]}
                      setOverride={(value) => {
                        changeAccessCutoff(SPRING_TRAINING, ACCESS, value);
                      }}
                    />
                  </Card.Body>
                </Card>
              </Column>
              <Column width={2}>
                <Card className="mt-2 mt-md-0">
                  <Card.Header>Event Cutoff</Card.Header>
                  <Card.Body>
                    <PersonalBusinessCutoff
                      override={convertFromEventTypeDTO(department[SPRING_TRAINING][CUTOFF])}
                      setOverride={(value) => {
                        changeAccessCutoff(SPRING_TRAINING, CUTOFF, convertToEventTypeDTO(value));
                      }}
                    />
                  </Card.Body>
                </Card>
              </Column>
            </Row>
          </Section>
        )}
        {department.regularSeason && (
          <Section className="mt-2" sectionKey="regular-season" header="Regular Season Requests">
            <Row>
              <Column width={3}>
                <PersonalOnOff
                  value={department.regularPersonalRequests}
                  onChange={(value) => onPersonalChange("regularPersonalRequests", value)}
                />
              </Column>
            </Row>
            <Row className="mt-2">
              <Column width={2}>
                <Card>
                  <Card.Header>Event Access</Card.Header>
                  <Card.Body>
                    <AccessCutoff
                      override={department[REGULAR_SEASON][ACCESS]}
                      setOverride={(value) => {
                        changeAccessCutoff(REGULAR_SEASON, ACCESS, value);
                      }}
                    />
                  </Card.Body>
                </Card>
              </Column>
              <Column width={2}>
                <Card className="mt-2 mt-md-0">
                  <Card.Header>Event Cutoff</Card.Header>
                  <Card.Body>
                    <PersonalBusinessCutoff
                      override={convertFromEventTypeDTO(department[REGULAR_SEASON][CUTOFF])}
                      setOverride={(value) => {
                        changeAccessCutoff(REGULAR_SEASON, CUTOFF, convertToEventTypeDTO(value));
                      }}
                    />
                  </Card.Body>
                </Card>
              </Column>
            </Row>
          </Section>
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="primary" disabled={!hasEdited} onClick={save}>
            Save
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminEditDepartments;
