import { Button, DesktopTabletView, SubSection, useLoading } from "best-common-react";
import React from "react";
import { deleteEventOverrides, saveEventOverrides } from "../../api/RequesTixApi";
import { defaultAccess, defaultCutoff } from "../../constants/CutoffConstants";
import { DepartmentByGameDTO } from "../../types/Department";
import { GameDTO } from "../../types/Game";
import { convertFromEventTypeDTO, convertToEventTypeDTO } from "../../util/CutoffUtil";
import EventAccessBulkOverride from "./EventAccessBulkOverride";
import EventAccessTable from "./EventAccessTable";
import OverrideDeleteModal from "./OverrideDeleteModal";
import OverrideSaveModal from "./OverrideSaveModal";

type EventAccessProps = {
  event: GameDTO;
  departments: DepartmentByGameDTO[];
  reload: () => Promise<any>;
};

const EventAccess = ({ event, departments, reload }: EventAccessProps) => {
  const { setLoading } = useLoading();
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showBulkOverrideModal, setBulkOverrideModal] = React.useState(false);
  const [selectedDepartment, setSelectedDepartment] = React.useState({});
  const [personalRequestsOverride, setPersonalRequestsOverride] = React.useState(null);
  const [accessOverride, setAccessOverride] = React.useState({});
  const [cutoffOverride, setCutoffOverride] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [hasEdited, setHasEdited] = React.useState(false);

  const timezone = event.venue.timeZone.id;

  const onOverrideAdd = (department) => {
    setSelectedDepartment(department);
    setAccessOverride(defaultAccess);
    setCutoffOverride(defaultCutoff);
    setPersonalRequestsOverride(null);
    setIsEdit(false);
    setShowSaveModal(true);
  };

  const onOverrideEdit = (department) => {
    setSelectedDepartment(department);
    setAccessOverride(department.accessOverride ? { ...department.accessOverride, active: true } : defaultAccess);
    setCutoffOverride(
      department.cutoffOverride
        ? { ...convertFromEventTypeDTO(department.cutoffOverride), active: true }
        : defaultCutoff
    );
    setIsEdit(true);
    setShowSaveModal(true);
  };

  const onOverrideDelete = (department) => {
    setSelectedDepartment(department);
    setShowDeleteModal(true);
  };

  const onAccessEdit = (value) => {
    setHasEdited(true);
    setAccessOverride(value);
  };

  const onCutoffEdit = (value) => {
    setHasEdited(true);
    setCutoffOverride(value);
  };

  const onPersonalRequestsSelect = (value) => {
    setHasEdited(true);
    setPersonalRequestsOverride(value);
  };

  const onOverrideSave = async () => {
    //@ts-ignore
    const access = accessOverride.active ? accessOverride : null;
    //@ts-ignore
    const cutoff = cutoffOverride.active ? cutoffOverride : null;

    try {
      setLoading(true);
      await saveEventOverrides({
        //@ts-ignore
        departmentIds: [selectedDepartment.departmentId],
        gamePk: event.gamePk,
        personalRequestsOverride,
        //@ts-ignore
        accessOverride: access,
        cutoffOverride: convertToEventTypeDTO(cutoff),
      });
      setShowSaveModal(false);
      setHasEdited(false);
      await reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onBulkSave = async () => {
    try {
      setLoading(true);
      setBulkOverrideModal(false);
      await reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setShowDeleteModal(false);
      //@ts-ignore
      await deleteEventOverrides(selectedDepartment.departmentId, event.gamePk);
      await reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setSelectedDepartment({
      id: null,
      name: "",
    });
    setShowSaveModal(false);
    setShowDeleteModal(false);
    setBulkOverrideModal(false);
    setHasEdited(false);
  };

  return (
    <>
      <OverrideSaveModal
        personalRequestsOverride={personalRequestsOverride}
        setPersonalRequestsOverride={onPersonalRequestsSelect}
        accessOverride={accessOverride}
        setAccessOverride={onAccessEdit}
        cutoffOverride={cutoffOverride}
        setCutoffOverride={onCutoffEdit}
        //@ts-ignore
        department={selectedDepartment.departmentName}
        show={showSaveModal}
        onSave={onOverrideSave}
        onClose={onClose}
        isEdit={isEdit}
        hasEdited={hasEdited}
        timezone={timezone}
      />
      <OverrideDeleteModal
        //@ts-ignore
        department={selectedDepartment.departmentName}
        show={showDeleteModal}
        onDelete={onDelete}
        onClose={onClose}
      />
      <EventAccessBulkOverride
        personalRequestsOverride={personalRequestsOverride}
        setPersonalRequestsOverride={onPersonalRequestsSelect}
        accessOverride={accessOverride}
        setAccessOverride={setAccessOverride}
        cutoffOverride={cutoffOverride}
        setCutoffOverride={setCutoffOverride}
        isOpen={showBulkOverrideModal}
        onSave={onBulkSave}
        onClose={onClose}
        departments={departments}
        gamePk={event.gamePk}
        timezone={timezone}
      />
      <SubSection header="Ticket Access">
        <EventAccessTable
          event={event}
          departments={departments}
          onOverrideAdd={onOverrideAdd}
          onOverrideEdit={onOverrideEdit}
          onOverrideDelete={onOverrideDelete}
        />
        <DesktopTabletView>
          <div className="mt-3">
            <Button
              variant="default"
              onClick={() => {
                setBulkOverrideModal(true);
              }}
              disabled={!departments || !departments.length}
            >
              Bulk Override
            </Button>
          </div>
        </DesktopTabletView>
      </SubSection>
    </>
  );
};

export default EventAccess;
