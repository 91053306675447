import React from "react";
import styled from "styled-components";
import { GamePreviewDTO } from "../../../types/Game";
import { DATE_FORMAT_STR, formatDate } from "best-common-react";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border: 1px solid ${(props) => props.theme.palette.surfaceColorsSurfaceContainerLow};
  background-color: #ff9300;
  color: white;
`;

type EventCanceledProps = {
  game: GamePreviewDTO;
};

const EventCanceled: React.FC<EventCanceledProps> = ({ game }) => {
  const [display, setDisplay] = React.useState(false);
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    if (game.status && game.status.codedGameState === "C") {
      setDisplay(true);
      setMessage("Cancelled");
    } else if (game.rescheduledFrom) {
      const date = formatDate(game.rescheduledFrom, DATE_FORMAT_STR);
      setDisplay(true);
      setMessage(`Original date - ${date}`);
    } else if (game.status && game.status.codedGameState === "D") {
      setDisplay(true);
      setMessage("Postponed");
    } else {
      setDisplay(false);
      setMessage("");
    }
  }, [game]);

  return (
    display && (
      <Container>
        <i className="fas fa-exclamation-circle" />
        <div className="ms-2">{message}</div>
      </Container>
    )
  );
};

export default EventCanceled;
