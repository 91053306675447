import {
  AttributeHeader,
  AttributeValue,
  Button,
  Card,
  Checkbox,
  Column,
  StickyFooterButtons,
  Title,
  useLoading,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  adminSaveRequest,
  changeRequestGame,
  getEvent,
  getRequest,
  getRequestActionState,
  getVenueAcceptedBillings,
  updateRequestState,
} from "../../../api/RequesTixApi";
import ActionForm from "../../../components/requests/admin/action/ActionForm";
import AdminChangeRequestModal from "../../../components/requests/admin/AdminChangeRequestModal";
import RequestAllotmentTable from "../../../components/requests/admin/RequestAllotmentTable";
import RequestInformation from "../../../components/requests/form/RequestInformation";
import TicketRequestForm from "../../../components/requests/form/TicketRequestForm";
import DeleteTicketRequest from "../../../components/requests/personal/DeleteTicketReqest";
import RouteConstants from "../../../constants/RouteConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { TicketRequestProvider } from "../../../contexts/TicketRequestContext";
import { ActionStateDTO } from "../../../types/Action";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { GameDTO, SurroundingGame } from "../../../types/Game";
import { FlattenedTicketRequest, SaveTicketRequestDTO, TicketRequest } from "../../../types/TicketRequest";
import { VenueAcceptedBilling } from "../../../types/Venue";
import { useRouteParams } from "../../../util/RouteUtil";
import { useRequestValidation, useValidCreditCard } from "../../../util/TicketRequestUtil";
import { formatSubmittedDate } from "../../../util/TimeUtil";

const massageData = (data: TicketRequest): FlattenedTicketRequest => {
  const sGames: string[] = data.surroundingGames
    ? (data.surroundingGames.map((g: SurroundingGame) => g?.surroundingGamePk?.toString()) as string[])
    : [];
  //@ts-ignore
  return {
    ...data,
    type: data.requestCategory ? data.requestCategory.requestCategoryName : null,
    requesterId: data.requester ? data.requester.employeeUserId : null,
    submitterId: data.submitter ? data.submitter.employeeUserId : null,
    projectId: data.project ? data.project.projectId : null,
    businessId: data.business ? data.business.businessId : null,
    //@ts-ignore
    surroundingGames: sGames,
    maxPriceOptionId: data.maxPriceOption ? data.maxPriceOption.maxPriceOptionId : null,
  };
};

const convertRequest = (request: FlattenedTicketRequest): SaveTicketRequestDTO => ({
  approvingSupervisorId: request.approvingSupervisor ? request.approvingSupervisor.employeeUserId : null,
  businessId: request.businessId,
  comments: request.comments,
  deliveryEmail: request.deliveryEmail,
  forGovernmentUse: request.forGovernmentUse,
  forCharityUse: request.forCharityUse,
  gamePk: request.gamePk,
  maxPriceOptionId: request.maxPriceOptionId,
  notifyRequester: request.notifyRequester,
  phoneNumber: request.phoneNumber,
  projectId: request.projectId,
  quantity: request.quantity,
  recipient: request.recipient,
  recipientFirst: request.recipientFirst,
  recipientLast: request.recipientLast,
  requestId: request.requestId,
  requestType: request.requestType,
  requesterId: request.requester.employeeUserId,
  seasonTicketRequest: request.seasonTicketRequest,
  submitterId: request.submitter.employeeUserId,
  surroundingGames: request.surroundingGames,
  type: request.type,
  userBilling: request.userBilling,
});

type AdminEditRequestProps = {
  request: FlattenedTicketRequest;
  setRequest: (value: FlattenedTicketRequest) => void;
  stateId: number;
  close: (requestId: string | number) => void;
};

const AdminEditRequest: React.FC<AdminEditRequestProps> = ({ request, setRequest, stateId, close }) => {
  const { setLoading } = useLoading();
  const { isAdmin } = useAuth();
  const [showChangeGameModal, setShowChangeGameModal] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [actionState, setActionState] = useState<ActionStateDTO>();
  const [allotment, setAllotment] = useState<UserAllotmentCountDTO>();
  const [allotments, setAllotments] = useState<UserAllotmentCountDTO[]>([]);
  const [statusId, setStatusId] = useState<number>(stateId);
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [venueBillings, setVenueBillings] = useState<VenueAcceptedBilling[]>([]);
  const [game, setGame] = useState<GameDTO>(undefined);
  const isRequestValid: boolean = useRequestValidation(request, allotment);
  const isCardValid: boolean = useValidCreditCard(request, venueBillings, game?.venue);

  const updateRequest = (showSuccessAlert?: boolean, refresh?: boolean) => {
    return new Promise((resolve) => {
      const requestToSave: SaveTicketRequestDTO = convertRequest(request);
      adminSaveRequest(requestToSave, showSuccessAlert).then(() => {
        updateRequestState(request.requestId, statusId, actionState, !!showSuccessAlert).then(() => {
          if (refresh) {
            void getRequestData(request.requestId);
            setHasEdited(false);
          }
          resolve(true);
        });
      });
    });
  };

  const save = () => {
    if (formValid) {
      setLoading(true);
      const requestToSave: SaveTicketRequestDTO = convertRequest(request);
      adminSaveRequest(requestToSave, true).then(() => {
        updateRequestState(request.requestId, statusId, actionState, false).then(() => {
          setLoading(false);
          close(request.requestId);
        });
      });
    }
  };

  const changeRequest = (value: FlattenedTicketRequest) => {
    setRequest(value);
    setHasEdited(true);
  };

  const changeStatus = (value: number) => {
    setStatusId(value);
    setHasEdited(true);
  };

  const changeActionState = (value: ActionStateDTO) => {
    setActionState(value);
    setHasEdited(true);
  };

  const changeGame = () => {
    setShowChangeGameModal(true);
  };

  const onChangeRequestSave = (gamePk) => {
    changeRequestGame(request.requestId, gamePk).then((res: TicketRequest) => {
      const { originalGamePk, gamePk } = res;
      setRequest({ ...request, originalGamePk, gamePk });
      setShowChangeGameModal(false);
    });
  };

  const getRequestData = async (requestId: number) => {
    try {
      setLoading(true);
      const actionData: ActionStateDTO = await getRequestActionState(requestId);
      setActionState(actionData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    try {
      setLoading(true);
      const actionData: ActionStateDTO = await getRequestActionState(request.requestId);
      setActionState(actionData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!request) {
      void getRequestData(request.requestId);
    }
  }, [request?.requestId]);

  useEffect(() => {
    if (!!request) {
      if (stateId) {
        setStatusId(stateId);
        setHasEdited(true);
      } else {
        setStatusId(request.requestState.requestStateId);
      }
    }
  }, [request?.requestState, stateId]);

  useEffect(() => {
    if (!!request) {
      setUserId(request.requester.employeeUserId);
    }
  }, [request?.requester]);

  useEffect(() => {
    if (request?.gamePk && game?.gamePk !== request?.gamePk) {
      getEvent(request.gamePk).then((data: GameDTO) => {
        setGame(data);
      });
    }
  }, [request?.gamePk]);

  useEffect(() => {
    if (game?.venue?.id) {
      getVenueAcceptedBillings(game.venue.id).then((data: VenueAcceptedBilling[]) => {
        setVenueBillings(data);
      });
    }
  }, [game?.venue]);

  useEffect(() => {
    if (request?.type) {
      setAllotment(
        allotments.find(
          (allotment) =>
            allotment.season.seasonId === request.season.seasonId &&
            allotment.allotmentType.allotmentTypeName.toLowerCase() === request.type.toLowerCase()
        )
      );
    }
  }, [allotments, request]);

  useEffect(() => {
    setFormValid(isRequestValid && hasEdited && isCardValid);
  }, [isRequestValid, hasEdited, isCardValid]);

  useEffect(() => {
    if (stateId && statusId) {
      setTimeout(() => {
        const id = `action-form`;
        const elem = document.getElementById(id);
        if (elem) {
          elem.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }, 0);
    }
  }, [stateId, statusId]);

  return (
    <>
      {!!game && (
        <AdminChangeRequestModal
          show={showChangeGameModal}
          game={game}
          onClose={() => setShowChangeGameModal(false)}
          onSave={onChangeRequestSave}
        />
      )}
      <Card>
        <Card.Header>Ticket Requests</Card.Header>
        <Card.Body>
          {!!request?.requestId && request?.requester && userId && actionState ? (
            <>
              <div className="d-flex flex-md-nowrap flex-wrap">
                <RequestInformation
                  originalGamePk={request.originalGamePk}
                  type={request.type}
                  userId={userId}
                  setAccessible={() => {}}
                  setAllotments={setAllotments}
                  allotments={allotments}
                  changeGame={changeGame}
                />
                <TicketRequestForm
                  ticketRequest={request}
                  setTicketRequest={changeRequest}
                  allotment={allotment}
                  canSelectGame={true}
                  canEditCreditCard={!actionState?.hasRequestBeenCharged}
                />
              </div>
              {isAdmin && (
                <div className="row mt-4">
                  <Column width={2}>
                    <>
                      <Title>Request Details</Title>
                      <div className="row">
                        <Column width={2}>
                          <AttributeHeader>Requester</AttributeHeader>
                          <AttributeValue>
                            {request.requester.firstName} {request.requester.lastName}
                          </AttributeValue>
                        </Column>
                        <Column width={2}>
                          <AttributeHeader>Requester Email</AttributeHeader>
                          <AttributeValue>
                            <a href={`mailto:${request.requester.email}`}>{request.requester.email}</a>
                          </AttributeValue>
                        </Column>
                        <Column width={2}>
                          <AttributeHeader>Title</AttributeHeader>
                          <AttributeValue>{request.requester.title}</AttributeValue>
                        </Column>
                        <Column width={2}>
                          <AttributeHeader>Department</AttributeHeader>
                          <AttributeValue>{request.requester.department.departmentName}</AttributeValue>
                        </Column>
                        <Column width={2}>
                          <AttributeHeader>Submitted By</AttributeHeader>
                          <AttributeValue>
                            {request.submitter.firstName} {request.submitter.lastName}
                          </AttributeValue>
                        </Column>
                        <Column width={2}>
                          <AttributeHeader>Date Submitted</AttributeHeader>
                          <AttributeValue>{formatSubmittedDate(request.createdTs)}</AttributeValue>
                        </Column>
                      </div>
                      <div className="row mt-0 mt-md-2">
                        <Column width={2}>
                          <RequestAllotmentTable
                            requestId={request.requestId}
                            category={request.requestCategory.requestCategoryName}
                          />
                        </Column>
                        <Column width={2}>
                          <Checkbox
                            id="seasonTicketRequest"
                            checked={request.seasonTicketRequest}
                            label="Season Ticket Request"
                            onChange={() => {
                              setRequest({
                                ...request,
                                seasonTicketRequest: !request.seasonTicketRequest,
                              });
                              setHasEdited(true);
                            }}
                          />
                        </Column>
                      </div>
                    </>
                  </Column>
                  <Column width={2}>
                    {statusId && actionState && (
                      <>
                        <ActionForm
                          game={game}
                          statusId={statusId}
                          setStatusId={changeStatus}
                          actionState={actionState}
                          setActionState={changeActionState}
                          request={request}
                          save={updateRequest}
                          refresh={refresh}
                        />
                        <div id="action-form" />
                      </>
                    )}
                  </Column>
                </div>
              )}
            </>
          ) : (
            <div />
          )}
        </Card.Body>
        <Card.Footer>
          <StickyFooterButtons startsOpen={false}>
            <DeleteTicketRequest requestId={request.requestId} goBack={close} />
            <Button variant="primary" onClick={save} disabled={!formValid}>
              Save
            </Button>
            <Button variant="default" onClick={() => close(request.requestId)}>
              {formValid ? "Cancel" : "Close"}
            </Button>
          </StickyFooterButtons>
        </Card.Footer>
      </Card>
    </>
  );
};

type Params = {
  requestId: string;
};

const AdminTicketRequestEditContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { requestId: requestIdParam } = useParams<Params>();
  const requestId: number = parseInt(requestIdParam);
  const { stateId } = useRouteParams(location.search);
  const { setLoading } = useLoading();
  const [request, setRequest] = useState<FlattenedTicketRequest>();

  const close = (requestId: string | number) => {
    let go;
    //@ts-ignore
    if (location?.state?.previousLocation) {
      //@ts-ignore
      go = location.state.previousLocation;
      if (requestId) {
        go.search += `&requestId=${requestId}`;
      }
    } else {
      go = RouteConstants.ADMIN.REQUESTS + "?view=1";
      if (requestId) {
        go += `&requestId=${requestId}`;
      }
    }
    navigate(go);
  };

  const getRequestData = async () => {
    try {
      setLoading(true);
      const resp = await getRequest(requestId);
      setRequest(massageData(resp));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestId) {
      void getRequestData();
    }
  }, [requestId]);

  return (
    <TicketRequestProvider gamePk={request?.gamePk.toString()}>
      {!!request && (
        <AdminEditRequest request={request} setRequest={setRequest} stateId={parseInt(stateId)} close={close} />
      )}
    </TicketRequestProvider>
  );
};

export default AdminTicketRequestEditContainer;
