import { TicketRequestAllotmentTypes } from "../constants/TicketRequestConstants";
import { UserAllotmentCountDTO } from "../types/Allotment";

const getAllotmentsForYearAndGameType = (
  allotments: UserAllotmentCountDTO[],
  year: string,
  gameType: string
): UserAllotmentCountDTO[] => {
  if (allotments) {
    return allotments.filter((allotment) => {
      const correctType =
        gameType === "R"
          ? allotment.season.seasonType.seasonTypeId === 2
          : allotment.season.seasonType.seasonTypeId === 1;
      return allotment.season.year.toString() === year && correctType;
    });
  }
  return [];
};

const getAllotmentByType = (allotments: UserAllotmentCountDTO[], allotmentTypeId: number): UserAllotmentCountDTO => {
  if (allotments) {
    return allotments.find((allotment) => allotment.allotmentType.allotmentTypeId === allotmentTypeId);
  }
  return undefined;
};

export const hasAllotmentLeft = (allotment: UserAllotmentCountDTO): boolean =>
  allotment ? allotment.unlimited || allotment.remaining > 0 : false;

export const useAllotmentAmounts = (
  allotments: UserAllotmentCountDTO[],
  year: string,
  gameType: string
): { hasBusinessAllotment: boolean; hasPersonalAllotment: boolean } => {
  const allotmentsForGame: UserAllotmentCountDTO[] = getAllotmentsForYearAndGameType(allotments, year, gameType);
  const personal = getAllotmentByType(allotmentsForGame, TicketRequestAllotmentTypes.PERSONAL);
  const business = getAllotmentByType(allotmentsForGame, TicketRequestAllotmentTypes.BUSINESS);
  return { hasBusinessAllotment: hasAllotmentLeft(business), hasPersonalAllotment: hasAllotmentLeft(personal) };
};
