import { DataTable, DataTableColumn, Column, Hint, Row, Select, useLoading, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getRequestsByVenueForSeasonId } from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { RequestsByVenueDTO } from "../../../types/RequestsByVenueDTO";
import { getSeasonOption } from "../../../util/DropdownUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import { Season } from "../../../types/Season";
import { ReportDataDTO } from "../../../types/Reports";

const Columns: DataTableColumn<RequestsByVenueDTO>[] = [
  {
    name: "Team Name",
    key: "teamName",
    width: 200,
  },
  {
    name: "Venue Name",
    key: "venueName",
    minWidth: 250,
  },
  {
    name: "Total Requests",
    key: "totalRequests",
    width: 200,
    cellClass: "justify-content-center",
  },
  {
    name: "Approved Personal Requests",
    key: "approvedPersonalRequests",
    width: 200,
    cellClass: "justify-content-center",
  },
  {
    name: "Total Personal Requests",
    key: "personalRequests",
    width: 200,
    cellClass: "justify-content-center",
  },
  {
    name: "Approved Business Requests",
    key: "approvedBusinessRequests",
    width: 200,
    cellClass: "justify-content-center",
  },
  {
    name: "Total Business Requests",
    key: "businessRequests",
    width: 200,
    cellClass: "justify-content-center",
  },
];

const RequestsByVenueReport = ({ reportData, setReportData }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const { seasonsOptions } = useDropdowns();
  const { seasonId } = reportData;
  const [requestsByVenue, setRequestsByVenue] = useState<RequestsByVenueDTO[]>([]);
  const [totalRequests, setTotalRequest] = useState<number>(0);

  const getData = async (seasonId: number) => {
    try {
      setLoading(true);
      const result: RequestsByVenueDTO[] = await getRequestsByVenueForSeasonId(seasonId);
      setTotalRequest(result.reduce((acc, curr) => acc + curr.totalRequests, 0));
      setRequestsByVenue(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key: keyof ReportDataDTO, data: number) => {
    setReportData({ ...reportData, [key]: data });
  };

  useEffect(() => {
    if (!!seasonId) {
      void getData(seasonId);
    }
  }, [seasonId]);

  return (
    <div>
      <Row>
        <Column width={4}>
          <Select
            id="season"
            label="Season"
            options={seasonsOptions}
            value={getSeasonOption(seasonId, seasonsOptions)}
            onChange={(value: ValueOpt<Season>) => {
              onChange("seasonId", value.value.seasonId);
            }}
          />
        </Column>
      </Row>
      <Hint className="mt-2">{totalRequests} Total Requests</Hint>
      <DataTable className="mt-2" data={requestsByVenue} columns={Columns} />
    </div>
  );
};

export default RequestsByVenueReport;
