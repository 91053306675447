import {
  DataTable,
  DataTableColumn,
  DataTableDataType,
  FormatterType,
  Icon,
  Link,
  SortFilters,
  sortWithFunction,
  TextFormatter,
  toLower,
  urlReplace,
  MatchUp,
  StatsTeam,
  StatsGameStatus,
  StatsTimeZone,
} from "best-common-react";
import * as H from "history";
import React, { useEffect } from "react";
import styled from "styled-components";
import RouteConstants from "../../constants/RouteConstants";
import { AdminGameDTO } from "../../types/Game";
import { RequestixLocationState } from "../../types/Routing";
import { formatGameDate } from "../../util/TimeUtil";
import EventsAccordion from "./EventsAccordion";

const IconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type EventTableData = DataTableDataType & {
  id: string;
  date: {
    id: string;
    location: H.Location<RequestixLocationState>;
    utc: Date;
    display: string;
    gameDate: Date;
    tz: StatsTimeZone;
    tbd: boolean;
  };
  game: {
    home: StatsTeam;
    away: StatsTeam;
    seriesDescription: string;
    status: StatsGameStatus;
  };
  homeTeam: string;
  venue: string;
  hasOverrides: boolean;
  searchString: string;
  location: H.Location<RequestixLocationState>;
  venueBlackedOut: boolean;
  eventBlackedOut: boolean;
};

const DateFormatter = ({ value: { id, location, display } }) => (
  <Link
    to={{
      pathname: urlReplace(RouteConstants.ADMIN.EVENTS_EDIT, { eventId: id }),
    }}
    state={{ previousLocation: location }}
  >
    {display}
  </Link>
);

const GameFormatter = ({ value: { away, home, seriesDescription } }: FormatterType<EventTableData>) => (
  <MatchUp away={away} home={home} description={seriesDescription} />
);

export const IsActiveFormatter = ({ value }) => !!value && <Icon iconName="fa fa-check" />;

export const OnOffFormatter = ({ value, ...rest }: FormatterType<EventTableData>) => (
  <TextFormatter {...rest} value={!!value ? "On" : "Off"} />
);

const HasOverride = ({ value }) =>
  value ? (
    <IconHolder>
      <Icon iconName="fa fa-check" />
    </IconHolder>
  ) : null;

const sort = (values: EventTableData[], sortFilters: SortFilters<EventTableData>) => {
  if (sortFilters.direction === "ASC") {
    if (sortFilters.key === "date") {
      return sortWithFunction(values, "date", 1, (a) => toLower(a.utc));
    } else if (sortFilters.key === "game") {
      return sortWithFunction(values, "homeTeam", 1, toLower);
    } else {
      return sortWithFunction(values, sortFilters.key, 1, toLower);
    }
  } else if (sortFilters.direction === "DESC") {
    if (sortFilters.key === "date") {
      return sortWithFunction(values, "date", -1, (a) => toLower(a.utc));
    } else if (sortFilters.key === "game") {
      return sortWithFunction(values, "homeTeam", -1, toLower);
    } else {
      return sortWithFunction(values, sortFilters.key, -1, toLower);
    }
  } else {
    return sortWithFunction(values, "date", 1, (a) => toLower(a.utc));
  }
};

type EventsTableProps = {
  values: AdminGameDTO[];
  searchTerm: string;
  location: H.Location<RequestixLocationState>;
  loading: boolean;
};

function EventsTable({ values = [], searchTerm, location, loading }: EventsTableProps) {
  const [events, setEvents] = React.useState<EventTableData[]>([]);
  const [sortFilters, setSortFilters] = React.useState<SortFilters<EventTableData>>({ key: "date", direction: "ASC" });
  const cols: DataTableColumn<EventTableData>[] = [
    { key: "date", name: "Date", width: 225, readonlyFormatter: DateFormatter, sortable: true },
    { key: "game", name: "Game", width: 150, readonlyFormatter: GameFormatter, sortable: true },
    { key: "venue", name: "Venue", width: 300, sortable: true },
    { key: "hasOverrides", name: "Override(s) Set", readonlyFormatter: HasOverride, width: 150, sortable: true },
    {
      key: "venueBlackedOut",
      name: "Venue Requests",
      width: 125,
      cellClass: "justify-content-center",
      readonlyFormatter: OnOffFormatter,
    },
    {
      key: "eventBlackedOut",
      name: "Game Blackout",
      width: 150,
      cellClass: "justify-content-center",
      readonlyFormatter: IsActiveFormatter,
    },
    {
      key: "dayOfRequestCancelled",
      name: "Today's Tickets Requests Cancelled",
      width: 250,
      cellClass: "justify-content-center",
      readonlyFormatter: IsActiveFormatter,
    },
  ];

  useEffect(() => {
    //@ts-ignore
    const events: EventTableData[] = values
      .map((event: AdminGameDTO) => {
        const startTimeTBD = !!event.status ? event.status.startTimeTBD : null;
        return {
          id: event.gamePk,
          date: {
            id: event.gamePk,
            location: location,
            utc: event.gameDate,
            display: formatGameDate(event.gameDate, event.venue.timeZone, startTimeTBD),
            gameDate: event.gameDate,
            tz: event.venue.timeZone,
            tbd: startTimeTBD,
          },
          game: {
            home: event.teams.home.team,
            away: event.teams.away.team,
            seriesDescription: event.seriesDescription,
            status: event.status,
          },
          homeTeam: event.teams.home.team.name,
          venue: event.venue.name,
          hasOverrides:
            event.accessOverrides.length > 0 ||
            event.cutoffOverrides.length > 0 ||
            event.personalRequestsOverrides.length > 0,
          searchString:
            formatGameDate(event.gameDate, event.venue.timeZone, startTimeTBD) +
            " " +
            event.teams.away.team.name +
            " @ " +
            event.teams.home.team.name +
            " " +
            event.venue.name,
          location: location,
          venueBlackedOut: event.venueBlackedOut,
          eventBlackedOut: event.eventBlackedOut,
          dayOfRequestCancelled: event.dayOfRequestCancelled,
        };
      })
      .filter((event) => event.searchString.toLowerCase().includes(searchTerm.toLowerCase()));
    setEvents(sort(events, sortFilters));
  }, [values, searchTerm]);

  useEffect(() => {
    setEvents(sort(events, sortFilters));
  }, [sortFilters]);

  return (
    <DataTable
      data={events}
      columns={cols}
      sortFunction={(key, direction) => setSortFilters({ key, direction })}
      accordion={EventsAccordion}
      sortColumn={sortFilters["key"]}
      sortDirection={sortFilters["direction"]}
      statusTextOverride={(_selectedCount, totalCount) => `${totalCount} Events`}
      loading={loading}
    />
  );
}
export default EventsTable;
