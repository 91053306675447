import { includes, ThemeType, useAlerts, lightenColor } from "best-common-react";
import * as H from "history";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { isValidEmail } from "best-common-react";
import AlertConstants from "../constants/AlertConstants";
import fulfillmentTypes from "../constants/FulfillmentTypeConstants";
import UserAllotmentConstants from "../constants/UserAllotmentConstants";
import { useAuth } from "../contexts/AuthContext";
import { UserAllotmentCountDTO } from "../types/Allotment";
import { RequestTypeDesc } from "../types/RequestType";
import { FlattenedTicketRequest, TicketRequest, TicketRequestDTO } from "../types/TicketRequest";
import { hasAllotmentLeft } from "./AllotmentUtil";
import { isCCExpired } from "./CreditCardUtil";

export const isChargeDept = (request: TicketRequest | TicketRequestDTO) =>
  request?.requestFulfillmentType?.requestFulfillmentTypeId === fulfillmentTypes.CHARGE_DEPT;

export const isChargeCard = (request: TicketRequest | TicketRequestDTO) =>
  request?.requestFulfillmentType?.requestFulfillmentTypeId === fulfillmentTypes.CHARGE_CARD;

export const isComp = (request: TicketRequest | TicketRequestDTO): boolean =>
  request?.requestFulfillmentType?.requestFulfillmentTypeId === fulfillmentTypes.COMP;

export const isBusiness = (type: RequestTypeDesc): boolean => UserAllotmentConstants.BUSINESS === type?.toLowerCase();

export const isBusinessType = (ticketRequest): boolean => ticketRequest?.type === UserAllotmentConstants.BUSINESS;

export const isCompOnly = (ticketRequest: TicketRequest): boolean => ticketRequest?.requestType?.requestTypeId === 1;

export const isChargeIfNecessary = (ticketRequest: TicketRequest): boolean =>
  ticketRequest?.requestType?.requestTypeId === 2;

export const isChargeDepartment = (ticketRequest: TicketRequest): boolean =>
  ticketRequest?.requestType?.requestTypeId === 3;

export const useRequestValidation = (ticketRequest: FlattenedTicketRequest, allotment: UserAllotmentCountDTO) => {
  const { isAdmin } = useAuth();
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    setIsValid(
      !!ticketRequest &&
        /* Request On Behalf Of */
        ticketRequest.requester?.employeeUserId &&
        /* Project */
        (!isBusinessType(ticketRequest) || ticketRequest.projectId !== -1) &&
        /* Business */
        (!isBusinessType(ticketRequest) || !!ticketRequest.businessId) &&
        /* Pay By */
        ticketRequest.requestType?.requestTypeId &&
        /* Quantity */
        ticketRequest.quantity &&
        /* Credit Card */
        (!isChargeIfNecessary(ticketRequest) || !!ticketRequest.userBilling) &&
        /* Recipient */
        ticketRequest.recipientFirst?.length > 0 &&
        ticketRequest.recipientLast?.length > 0 &&
        /* For Government Official */
        typeof ticketRequest.forGovernmentUse === "boolean" &&
        /* For Government Official */
        (!isBusinessType(ticketRequest) ||
          (ticketRequest.approvingSupervisor && ticketRequest.approvingSupervisor?.employeeUserId)) &&
        /* Delivery Email */
        isValidEmail(ticketRequest.deliveryEmail) &&
        /* Remaining Allotment */
        (!isCompOnly(ticketRequest) || hasAllotmentLeft(allotment) || isAdmin)
    );
  }, [ticketRequest, allotment, isAdmin]);

  return isValid;
};

export const useValidCreditCard = (ticketRequest, venueBillings, venue) => {
  const { addAlert } = useAlerts();
  const [validCard, setValidCard] = useState(true);

  useEffect(() => {
    if (
      ticketRequest?.requestType?.requestTypeId &&
      (ticketRequest.requestType?.requestTypeId === 1 || ticketRequest.requestType?.requestTypeId === 3)
    ) {
      setValidCard(true);
      return;
    }
    if (venueBillings && ticketRequest?.userBilling?.ccType && venue) {
      const ccTypeId = ticketRequest.userBilling.ccType.ccTypeId;
      const match = venueBillings.filter((vb) => vb.creditCardType && vb.creditCardType.ccTypeId === ccTypeId);
      if (!match || !match.length) {
        const cardList = venueBillings.map((vb) => ` ${vb.creditCardType.ccTypeName}`);
        const errorMessage = `This type of card is not accepted by ${venue.name}. Accepted cards include${cardList}`;
        addAlert({ type: AlertConstants.TYPES.DANGER, text: errorMessage });
        setValidCard(false);
      } else {
        if (isCCExpired(ticketRequest.userBilling.ccExp)) {
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: "Credit card is expired. Please select a valid credit card.",
          });
          setValidCard(false);
        } else {
          setValidCard(true);
        }
      }
    }
  }, [ticketRequest?.userBilling, venueBillings, ticketRequest?.requestType]);

  return validCard;
};

export const requestsFreeTextSearch = (requests, query) =>
  query && query.length
    ? requests.filter(
        (request) =>
          includes(request.gamePk, query) ||
          includes(request.status?.requestStateName, query) ||
          includes(request.requestFulfillmentType?.requestFulfillmentTypeName, query) ||
          includes(request.requester, query) ||
          includes(request.recipient, query) ||
          includes(request.department, query) ||
          includes(request.quantity, query) ||
          includes(request.maxPrice, query) ||
          includes(request.category, query) ||
          includes(request.payBy, query) ||
          includes(request.project, query) ||
          includes(request.business, query) ||
          includes(request.submitted, query) ||
          includes(request.comments, query) ||
          includes(request.supervisor, query) ||
          includes(request.statusName, query) ||
          includes(request.totalTicketCost, query) ||
          includes(request.homeTeam, query)
      )
    : requests;

export const requestsFreeTextFilter = (requests: object, query: string): object => {
  const results = {};
  if (requests) {
    Object.keys(requests).forEach((key) => {
      results[key] = requestsFreeTextSearch(requests[key], query);
    });
  }
  return results;
};

export const getStatusColor = (
  theme: ThemeType,
  _requestId: number,
  requestStateId: number,
  activeRequest?: boolean
): string => {
  const lightPercent = theme.isDark ? 0 : 60;
  if (activeRequest) {
    return lightenColor(theme.palette.coreColorsOnSurfaceYellow, lightPercent);
  } else {
    switch (requestStateId) {
      case 1:
        return lightenColor(theme.palette.coreColorsWhite, lightPercent);
      case 2:
        return lightenColor(theme.palette.coreColorsOnSurfaceBlue, lightPercent);
      case 3:
        return lightenColor(theme.palette.coreColorsOnSurfaceGreen, lightPercent);
      case 4:
        return lightenColor(theme.palette.coreColorsOnSurfaceRed, lightPercent);
      default:
        return "";
    }
  }
};

export const removeRequestId = (location: H.Location): string => {
  let search = "?";
  if (location) {
    const params = qs.parse(location.search);
    Object.keys(params).forEach((key) => {
      if (key !== "requestId" && params[key]) {
        search += `&${key}=${params[key]}`;
      }
    });
  }
  return search;
};
