import { useLoading, MatchUp } from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import { DayOfGameDTO } from "../../types/TodaysTickets";
import { useTimezone } from "../../util/TimeUtil";
import TodaysTicketsGameRequest from "./TodaysTicketsGameRequest";

type StyleProps = {
  isActive: boolean;
};

const Container = styled.div<StyleProps>`
  padding: 1rem;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background: ${(props) => (props.isActive ? "white" : "#e7e7e7")};
  &:hover {
    cursor: ${(props) => (props.isActive ? "pointer" : "not-allowed")};
  }
`;

const Game = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GameInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  font-size: 0.875rem;

  margin-top: 1rem;
  font-weight: bold;
  display: flex;
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

const VenueName = styled.div`
  margin-top: 0.5rem;
`;

const TicketDescription = styled.div`
  margin-top: 0.5rem;
`;

type DayOfGameProps = {
  game: DayOfGameDTO;
  refreshData?: () => void;
  allotment?: number;
  isLotto?: boolean;
};

const TodaysTicketsGame: React.FC<DayOfGameProps> = ({ game, refreshData, allotment, isLotto = false }) => {
  const { setLoading } = useLoading();
  const [requestOpen, setRequestOpen] = useState<boolean>(false);
  const tz = game.venue ? game.venue.timeZone : null;
  const { time, day, date } = useTimezone(game.gameDate, tz, {
    startTimeTBD: false,
    statusCode: "",
    detailedState: "",
    codedGameState: "",
    abstractGameState: "",
    abstractGameCode: "",
  });
  const teamId: number = game.home.id;
  const canRequest: boolean = game.ticketsLeft > 0 && allotment > 0;
  console.log(`Can request to game: ${canRequest}, allotment is: ${allotment}`);

  return (
    <>
      <TodaysTicketsGameRequest
        isOpen={requestOpen}
        onSave={!!refreshData ? refreshData : () => {}}
        onClose={() => setRequestOpen(false)}
        gamePk={game.gamePk}
        teamId={teamId}
        ticketsLeft={game.ticketsLeft}
        allotment={allotment}
        isLotto={isLotto}
      />
      <Container
        isActive={canRequest}
        onClick={() => {
          if (canRequest) {
            setLoading(true);
            setRequestOpen(true);
          }
        }}
      >
        <Game>
          <GameInformation>
            <div>
              <Header>{date}</Header>
              <Description>
                {day}
                <Game className="ms-2">{time}</Game>
              </Description>
            </div>
          </GameInformation>
          <div>
            <MatchUp home={game.home} away={game.away} />
          </div>
        </Game>
        <VenueName>{game.venue.name}</VenueName>
        {game.ticketsLeft !== undefined && <TicketDescription>{game.ticketsLeft} Ticket(s) Left</TicketDescription>}
      </Container>
    </>
  );
};

export default TodaysTicketsGame;
