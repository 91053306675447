import { DataTableDataType, StatsGame } from "best-common-react";
import { Business } from "./Business";
import { DigitalTicketDeliveryDTO } from "./DigitalTickets";
import { GamePreviewDTO } from "./Game";
import { UserDTO } from "./User";

export type SuiteRequestStatusID = 1 | 2 | 3 | 4 | 5;

export type SuiteRequestStatus = {
  suiteStatusId: SuiteRequestStatusID;
  status: string;
};

export const SuiteStatuses = {
  OPEN: 1 as SuiteRequestStatusID,
  APPROVED: 2 as SuiteRequestStatusID,
  DENIED: 3 as SuiteRequestStatusID,
  IN_REVIEW: 4 as SuiteRequestStatusID,
  COMPLETE: 5 as SuiteRequestStatusID,
};

export type SuiteRequestSaveDTO = {
  suiteRequestId?: number;
  suiteRequestStatus?: SuiteRequestStatus;
  business?: Business;
  requesterUser?: UserDTO;
  onBehalfOfUser?: UserDTO;
  onSiteContact?: string;
  isMlbPaying?: boolean;
  suiteCapacityMax?: number;
  suiteCapacityMin?: number;
  capacity?: string;
  ticketBudget?: number;
  preferredGamePk?: number;
  secondaryGamePk?: number;
  paymentName?: string;
  paymentBusinessName?: string;
  paymentEmail?: string;
  phoneNumber?: string;
  ballparkEmail?: string;
  submitted?: Date;
};

export type SuiteRequestDTO = SuiteRequestSaveDTO &
  DataTableDataType & {
    preferredGame: StatsGame;
  };

export type AdminSuiteRequestsDTO = {
  schedule: number[];
  games: { [key: number]: GamePreviewDTO };
  requests: { [key: number]: SuiteRequestDTO[] };
  totalRequests: { [key: number]: number };
};

export type AdminSuiteRequestStateDTO = {
  suiteRequestId: number;
  adminComments: string;
  requestSentToClub: boolean;
  emailDetailsSentToClub: boolean;
  selectedGamePk: number;
  numberOfTickets: number;
  suiteLocation: string;
  totalTicketCost: number;
  totalFbCost: number;
  ticketsDelivered: boolean;
  confirmationEmailNotes: string;
  confirmationEmailSent: boolean;
  confirmationEmailSentDate: Date;
  digitalTickets: DigitalTicketDeliveryDTO;
};

export type AdminSuiteRequest = {
  request: SuiteRequestDTO;
  actionState: AdminSuiteRequestStateDTO;
};

export type SuiteRequestDtdMapping = {
  suiteRequestDigitalTicketMapping: number;
  suiteRequestId: number;
  digitalTicketDelivery: DigitalTicketDeliveryDTO;
};
