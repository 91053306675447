import { Checkbox, Column } from "best-common-react";
import React from "react";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteInReviewAction = ({ actionState, setActionState }: AdminSuiteActionsProps) => {
  return (
    <Column width={2}>
      <Checkbox
        id="request-sent-to-club"
        label="Request Sent To Club"
        className="mt-4"
        checked={actionState.requestSentToClub}
        onChange={(value: boolean) => {
          setActionState({
            ...actionState,
            requestSentToClub: value,
          });
        }}
      />
    </Column>
  );
};

export default AdminSuiteInReviewAction;
