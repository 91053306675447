import {
  Accordion,
  Attribute,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  Icon,
  IconFormatter,
  IconLinkFormatter,
  Link,
  StatsGame,
} from "best-common-react";
import React from "react";
import GameDateFormatter from "../../../components/tables/GameDateFormatter";

export type DayOfTableData = DataTableDataType & {
  dayOfRequestId: number;
  firstName: string;
  lastName: string;
  ballparkEmail: string;
  numberOfTickets: number;
  game: StatsGame;
  ticketsSentDisplay: string;
  requestTime: string;
  editLink: string;
  status: string;
  statusId: number;
};

const TodaysTicketsAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<DayOfTableData>>> = ({
  data,
  ...rest
}) => {
  const {
    editLink,
    game,
    firstName,
    lastName,
    ballparkEmail,
    numberOfTickets,
    ticketsSentDisplay,
    status,
    requestTime,
  } = data as DayOfTableData;
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <Link to={editLink}>
            <Icon iconName="fa-pencil-alt" className="fa-lg me-3" />
          </Link>
          <GameDateFormatter value={game} row={data} />
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <Attribute header="First Name" value={firstName} />
          <Attribute header="Last Name" value={lastName} />
          <Attribute header="Email" value={ballparkEmail} />
          <Attribute header="Number of Tickets" value={numberOfTickets?.toString()} />
          <Attribute header="Tickets" value={ticketsSentDisplay} />
          <Attribute header="Status" value={status} />
          <Attribute header="Request Time" value={requestTime} />
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

type TodaysTicketsDataTableProps = {
  requests: DayOfTableData[];
  hasEdit?: boolean;
  hasDelete?: boolean;
  onDeleteClicked?: (index: number, value?: DayOfTableData) => void;
  loading?: boolean;
};

const TodaysTicketsDataTable = ({
  requests,
  hasDelete = false,
  hasEdit = false,
  onDeleteClicked,
  loading = false,
}: TodaysTicketsDataTableProps) => {
  const Cols: DataTableColumn<DayOfTableData>[] = [
    {
      key: "deleteLink",
      name: "",
      width: 35,
      onClick: onDeleteClicked,
      readonlyFormatter: IconFormatter,
      icon: "fa-trash-alt",
      fixed: true,
      visible: hasDelete,
    },
    {
      key: "editLink",
      name: "",
      width: 35,
      readonlyFormatter: IconLinkFormatter,
      icon: "fa-pencil-alt",
      fixed: true,
      visible: hasEdit,
    },
    { key: "game", name: "Game", width: 350, readonlyFormatter: GameDateFormatter, fixed: true },
    { key: "firstName", name: "First Name", minWidth: 200 },
    { key: "lastName", name: "Last Name", minWidth: 200 },
    { key: "ballparkEmail", name: "Ballpark Email", minWidth: 250 },
    { key: "numberOfTickets", name: "# of Tickets", width: 100 },
    { key: "ticketsSentDisplay", name: "Tickets Sent", minWidth: 250 },
    { key: "status", name: "Status", minWidth: 250 },
    { key: "requestTime", name: "Request Time", minWidth: 250 },
  ];

  return (
    <DataTable
      className="my-3"
      data={requests}
      columns={Cols}
      statusTextOverride={(_selected, total) => `${total} Requests`}
      accordion={TodaysTicketsAccordion}
      tableHeights={{
        maxHeightOffset: 600,
        autogrow: true,
      }}
      loading={loading}
    />
  );
};

export default TodaysTicketsDataTable;
