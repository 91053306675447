import {
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  Column,
  getRawDate,
  Row,
  Search,
  Select,
  ValueOpt,
  StatsTeam,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { ReportDataDTO } from "../../../types/Reports";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import ReconciliationDataTable from "./ReconciliationDataTable";

const ReconciliationReport = ({ reportData, setReportData, location, setCanExport }: ReportHandlerTypeProps) => {
  const { search, fulfillmentTypeId, homeTeamId, startDate, endDate } = reportData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });

  const { requestFulfillmentTypesOptions, getOptionByValue, teamsOptions } = useDropdowns();

  const fulfillmentTypesOptions: ValueOpt<number>[] = useMemo(
    () => [{ label: "All", value: -1 }, ...requestFulfillmentTypesOptions],
    [requestFulfillmentTypesOptions]
  );

  const onChange = (key: keyof ReportDataDTO, data: any) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    setDateRange(newDate);
  }, [startDate, endDate]);

  return (
    <>
      <Row>
        <Column width={4}>
          <DateRangeInput
            id="approval-date"
            label="Approval Date"
            value={dateRange}
            onChange={updateDate}
            maxRange={{
              months: 3,
            }}
            withPortal
            gutterBottom
          />
        </Column>
        <Column width={4}>
          <Select
            id="fulfillment"
            label="Fulfillment"
            options={fulfillmentTypesOptions}
            value={getOptionByValue(fulfillmentTypesOptions, fulfillmentTypeId ? fulfillmentTypeId * 1 : -1)}
            onChange={(value: ValueOpt<number>) => {
              const val = value.value === -1 ? null : value.value;
              onChange("fulfillmentTypeId", val);
            }}
          />
        </Column>
        <Column width={4}>
          <Select
            id="home-team-dropdown"
            label="Home Team"
            options={teamsOptions}
            value={teamsOptions.find((opt: ValueOpt<StatsTeam>) => {
              if (!!homeTeamId) {
                return opt.value.id === homeTeamId;
              } else {
                return !opt.value.id;
              }
            })}
            onChange={(team: ValueOpt<StatsTeam>) => onChange("homeTeamId", team.value.id || null)}
          />
        </Column>
        <Column width={4}>
          <Search
            id="search"
            label="Search Requests"
            value={search}
            onChange={(value: string) => {
              onChange("search", value);
            }}
            placeholder="begin typing to filter requests"
          />
        </Column>
      </Row>
      <ReconciliationDataTable
        setReportData={setReportData}
        reportData={reportData}
        location={location}
        setCanExport={setCanExport}
      />
    </>
  );
};

export default ReconciliationReport;
