import {
  Button,
  Email,
  Column,
  Input,
  Modal,
  Row,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { createUser } from "../../../api/RequesTixApi";
import { Department } from "../../../types/Department";
import { CreateUserDTO } from "../../../types/User";

type AdminCreateUserType = {
  show?: boolean;
  close: (saved: boolean) => void;
  departments: ValueOpt<Department>[];
};

const AdminCreateUser: React.FC<AdminCreateUserType> = ({ show = false, close, departments = [] }) => {
  const { setLoading } = useLoading();
  const [user, setUser] = useState<CreateUserDTO>({});
  const [canSave, setCanSave] = useState<boolean>(false);
  const [departmentOptions, setDepartmentOptions] = useState<ValueOpt<Department>[]>([]);
  const [validEmail, setValidEmail] = React.useState(false);

  const closeForm = (saved: undefined | boolean = false) => {
    setUser({});
    close(saved);
  };

  const createUserCall = async () => {
    try {
      setLoading(true);
      await createUser(user);
      closeForm(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (key: string, value: string | number) => {
    setUser({ ...user, [key]: value });
  };

  const getDepartmentOption = (
    value: number | undefined,
    options: ValueOpt<Department>[]
  ): ValueOpt<Department> | undefined => {
    return options.find((opt: ValueOpt<Department>) => opt?.value?.departmentId === value);
  };

  useEffect(() => {
    const allRequired =
      !!user?.firstName?.length &&
      !!user?.lastName?.length &&
      !!user?.email?.length &&
      !!user?.departmentId &&
      !!user?.directSupervisor?.length &&
      !!user?.jobTitle?.length &&
      !!user?.location?.length;
    setCanSave(allRequired && validEmail);
  }, [user, validEmail]);

  useEffect(() => {
    setDepartmentOptions(departments.filter((d: ValueOpt<Department>) => !!d.value));
  }, [departments]);

  return (
    <Modal show={show} size="xl">
      <Modal.Header close={() => closeForm()}>Create User</Modal.Header>
      <Modal.Body>
        <Row>
          <Column width={2}>
            <Input
              id="firstName"
              label="First Name"
              value={user.firstName}
              onChange={(value) => handleChange("firstName", value)}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Input
              id="lastName"
              label="Last Name"
              value={user.lastName}
              onChange={(value) => handleChange("lastName", value)}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Email
              id="email"
              label="Email"
              value={user.email}
              onChange={(value) => handleChange("email", value)}
              setValidEmail={setValidEmail}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Select
              id="department"
              label="Department"
              options={departmentOptions}
              value={getDepartmentOption(user?.departmentId, departmentOptions)}
              onChange={(value) => handleChange("departmentId", value?.value?.departmentId)}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Input
              id="jobTitle"
              label="Job Title"
              value={user.jobTitle}
              onChange={(value) => handleChange("jobTitle", value)}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Input
              id="location"
              label="Location"
              value={user.location}
              onChange={(value) => handleChange("location", value)}
              required
              gutterBottom
            />
          </Column>
          <Column width={2}>
            <Input
              id="directSupervisor"
              label="Direct Supervisor"
              value={user.directSupervisor}
              onChange={(value) => handleChange("directSupervisor", value)}
              required
              gutterBottom
            />
          </Column>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <StickyFooterButtons>
          <Button variant="primary" className="me-2" disabled={!canSave} onClick={createUserCall}>
            Save
          </Button>
          <Button variant="default" onClick={() => closeForm()}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminCreateUser;
