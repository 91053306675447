import {
  addWeeks,
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  formatDate,
  Column,
  NotApplicable,
  Row,
  Select,
  useLoading,
  isBefore,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { getGameRequestCounts, getSchedule, getUser } from "../api/RequesTixApi";
import Game from "../components/requests/card/Game";
import SeasonSelect from "../components/requests/card/SeasonSelect";
import RouteConstants from "../constants/RouteConstants";
import { useAuth } from "../contexts/AuthContext";
import { useDropdowns } from "../contexts/DropdownsContext";
import { useMetadata } from "../contexts/MetadataContext";
import { UserAllotmentCountDTO } from "../types/Allotment";
import { GameDTO, GameRequestCountMap } from "../types/Game";
import { UserDTO } from "../types/User";
import { updateRouteParams, useRouteParams } from "../util/RouteUtil";
import { seasonTypesCodes, SPRING_TRAINING } from "../util/SeasonUtil";
import { getTeamOption } from "../util/TeamUtil";
import { useLocation, useNavigate } from "react-router-dom";

const today = new Date();
const nextWeek = addWeeks(today, 1);
const todayStr = formatDate(today, DATE_FORMATTER_STR);
const nextWeekStr = formatDate(nextWeek, DATE_FORMATTER_STR);

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useAuth();
  const { teamsOptions } = useDropdowns();
  const { setLoading } = useLoading();
  const { activeSeason } = useMetadata();
  const [schedule, setSchedule] = useState<GameDTO[]>([]);
  const [user, setUser] = useState<UserDTO | undefined>(undefined);
  const [_allotments, setAllotments] = useState<UserAllotmentCountDTO[]>([]);
  const [gameRequestCounts, setGameRequestCounts] = useState<GameRequestCountMap>({});
  const [selectedSeason, setSelectedSeason] = useState(seasonTypesCodes[SPRING_TRAINING]);

  const { startDate = todayStr, endDate = nextWeekStr, homeTeamId, opponentTeamId } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const selectedHomeTeam = useMemo(() => getTeamOption(homeTeamId, teamsOptions), [homeTeamId, teamsOptions]);
  const selectedOpponent = useMemo(() => getTeamOption(opponentTeamId, teamsOptions), [opponentTeamId, teamsOptions]);
  const hasDateLimit: boolean = useMemo(() => !opponentTeamId && !homeTeamId, [opponentTeamId, homeTeamId]);

  const updateUrl = ({ startDate, endDate, homeTeamId, opponentTeamId }) => {
    updateRouteParams(navigate, RouteConstants.BASE, {
      startDate: startDate,
      endDate: endDate,
      homeTeamId: homeTeamId,
      opponentTeamId: opponentTeamId,
    });
  };

  const updateDate = (dateRange: DateRange<Date>) => {
    updateUrl({
      startDate: formatDate(dateRange.start, DATE_FORMATTER_STR),
      endDate: formatDate(dateRange.end, DATE_FORMATTER_STR),
      homeTeamId,
      opponentTeamId,
    });
  };

  const fetchUser = async () => {
    try {
      const res: UserDTO = await getUser(userInfo.employeeUserId);
      setUser(res);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSchedule = async (startDate: string, endDate: string, homeTeamId?: number, opponentTeamId?: number) => {
    try {
      setLoading(true);
      const res: GameDTO[] = await getSchedule(homeTeamId, opponentTeamId, startDate, endDate);
      setSchedule(res);
    } catch (e) {
      console.error(e);
      setSchedule([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchGameCounts = async (gamePks: number[]) => {
    const res: GameRequestCountMap = await getGameRequestCounts(gamePks);
    setGameRequestCounts(res);
  };

  useEffect(() => {
    if (activeSeason.seasonType) {
      setSelectedSeason(seasonTypesCodes[activeSeason.seasonType.seasonTypeName]);
    }
  }, [activeSeason]);

  useEffect(() => {
    if (isBefore(startDate, endDate)) {
      void fetchSchedule(startDate, endDate, homeTeamId, opponentTeamId);
    }
  }, [homeTeamId, opponentTeamId, startDate, endDate]);

  useEffect(() => {
    if (userInfo.employeeUserId) {
      void fetchUser();
    }
  }, [userInfo]);

  useEffect(() => {
    const gamePks: number[] = schedule ? schedule.map((s) => s.gamePk) : [];
    if (gamePks.length) {
      void fetchGameCounts(gamePks);
    }
  }, [schedule]);

  return (
    <Card>
      <Card.Header>Request Tickets</Card.Header>
      <Card.Body>
        <Row className="mt-2">
          <Column width={4}>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={selectedHomeTeam}
              onChange={(team) => updateUrl({ startDate, endDate, homeTeamId: team.value.id, opponentTeamId })}
            />
          </Column>
          <Column width={4}>
            <Select
              id="opponent-dropdown"
              label="Opponent"
              options={teamsOptions}
              value={selectedOpponent}
              onChange={(team) => updateUrl({ startDate, endDate, homeTeamId, opponentTeamId: team.value.id })}
            />
          </Column>
          <Column width={4}>
            <DateRangeInput
              id="date-range"
              label="Date"
              value={dateRange}
              onChange={(value: DateRange<any> | NotApplicable) => {
                updateDate(value as DateRange<Date>);
              }}
              maxRange={
                hasDateLimit
                  ? {
                      weeks: 1,
                    }
                  : undefined
              }
              withPortal
            />
          </Column>
          <Column width={4}>
            <SeasonSelect
              userId={user?.employeeUserId}
              year={activeSeason.year?.toString()}
              seasonType={selectedSeason}
              setSeasonType={setSelectedSeason}
              setAllotments={setAllotments}
              pendingLink={true}
            />
          </Column>
        </Row>
        <div>
          {schedule?.map((game: GameDTO) => (
            <Game
              key={game.gamePk}
              gamePk={game.gamePk}
              gameDate={game.gameDate}
              venue={game.venue}
              description={game.seriesDescription}
              teams={game.teams}
              access={game.access}
              cutoff={game.cutoff}
              personalRequests={game.personalRequests}
              blackedOut={game.blackedOut}
              requestCount={gameRequestCounts[game.gamePk]}
              status={game.status}
              promotions={game.atBatPromotions}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Home;
