import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  CustomMobileAccordionType,
  Icon,
  Typography,
} from "best-common-react";
import React from "react";
import styled from "styled-components";
import { EventAccessData } from "./EventAccessTable";

const EditDeleteHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OverrideText = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

const StrikeThrough = styled.div`
  text-decoration: line-through;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

const EventAccessAccordion = ({
  data: { override, department, access, personalCutoff, businessCutoff, personalRequests },
  ...rest
}: CustomMobileAccordionType<EventAccessData>) => (
  <Accordion {...rest}>
    <Accordion.Header>
      {override.isOverride ? (
        <EditDeleteHolder>
          <Icon iconName="fa-pencil-alt" className="fa-lg" onClick={() => override.onEdit(override.department)} />
          <OverrideText>Override</OverrideText>
          <Icon iconName="fa-trash-alt" className="fa-lg" onClick={() => override.onDelete(override.department)} />
        </EditDeleteHolder>
      ) : (
        <Button variant="link" onClick={() => override.onAdd(override.department)}>
          Add Override
        </Button>
      )}
      <Typography variant="div">{department}</Typography>
    </Accordion.Header>
    <Accordion.Body>
      <div>
        <AttributeHeader>Event Access (local time)</AttributeHeader>
        <AttributeValue>
          {!access.override ? (
            <div>{access.time}</div>
          ) : (
            <>
              <div>{access.override}</div>
              <StrikeThrough>{access.time}</StrikeThrough>
            </>
          )}
        </AttributeValue>

        <AttributeHeader>Personal Event Cutoff (local time)</AttributeHeader>
        <AttributeValue>
          {!personalCutoff.override ? (
            <div>{personalCutoff.time}</div>
          ) : (
            <>
              <div>{personalCutoff.override}</div>
              <StrikeThrough>{personalCutoff.time}</StrikeThrough>
            </>
          )}
        </AttributeValue>

        <AttributeHeader>Business Event Cutoff (local time)</AttributeHeader>
        <AttributeValue>
          {!businessCutoff.override ? (
            <div>{businessCutoff.time}</div>
          ) : (
            <>
              <div>{businessCutoff.override}</div>
              <StrikeThrough>{businessCutoff.time}</StrikeThrough>
            </>
          )}
        </AttributeValue>
        <AttributeHeader>Personal Requests</AttributeHeader>
        <AttributeValue>
          {personalRequests.override === null ? (
            <div>{personalRequests.enable ? "On" : "Off"}</div>
          ) : (
            <>
              <div>{personalRequests.override ? "On" : "Off"}</div>
              <StrikeThrough>{personalRequests.enable ? "On" : "Off"}</StrikeThrough>
            </>
          )}
        </AttributeValue>
      </div>
    </Accordion.Body>
  </Accordion>
);

export default EventAccessAccordion;
