import { SeasonTypeCode } from "../types/Season";
import { StatsGame } from "best-common-react";

export const SPRING_TRAINING = "Spring Training";
export const REGULAR_SEASON = "Regular Season";
export const POST_SEASON = "Post Season";

export const getSeasonTypeForGame = (game: StatsGame): SeasonTypeCode =>
  game?.gameType === "S"
    ? seasonTypesCodes[SPRING_TRAINING]
    : game?.gameType === "R"
    ? seasonTypesCodes[REGULAR_SEASON]
    : seasonTypesCodes[POST_SEASON];

export const seasonTypesCodes: { [key: string]: SeasonTypeCode } = {
  [SPRING_TRAINING]: {
    id: 1,
    code: "S",
    name: SPRING_TRAINING,
  },
  [REGULAR_SEASON]: {
    id: 2,
    code: "R",
    name: REGULAR_SEASON,
  },
  [POST_SEASON]: {
    id: 3,
    code: "P",
    name: POST_SEASON,
  },
};
