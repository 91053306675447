import React from "react";
import { createCreditCardToken, getCreditCardKey } from "../api/CyberSourceApi";
import { deleteCreditCard, saveCreditCard } from "../api/RequesTixApi";
import { useAuth } from "../contexts/AuthContext";
import { CyberSourceGenerateKeyResponse } from "../types/CyberSource";
import { BillingDTO, NewBillingDTO } from "../types/UserBilling";
import { getMonthsBetween, isAfter, lastDayOfMonth, ThemeType } from "best-common-react";

const defaultCC: NewBillingDTO = {
  userBillingId: undefined,
  firstName: "",
  lastName: "",
  countryId: 1,
  countryName: "United States",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  ccType: {},
  ccNickname: "",
  ccLast4: "",
  ccExp: "",
  ccToken: "",
  cvv: "",
  isDefault: false,
};

export const StatedRequiredCountryIds = [1, 2];

async function createCyberSourceToken(cc: BillingDTO, number: string | number) {
  const key: CyberSourceGenerateKeyResponse = await getCreditCardKey();
  const flex = {
    keyId: key.keyId,
    cardInfo: {
      cardNumber: number,
      cardExpirationMonth: cc.ccExp.split("/")[0],
      cardExpirationYear: cc.ccExp.split("/")[1],
      cardType: `00${cc.ccType.ccTypeId}`,
    },
  };
  return createCreditCardToken(flex);
}

const validCreditCard = (cc: NewBillingDTO): boolean =>
  !!cc.firstName?.length &&
  !!cc.lastName?.length &&
  !!cc.addressLine1?.length &&
  !!cc.countryId &&
  !!cc.city?.length &&
  (!StatedRequiredCountryIds.includes(cc.countryId) || cc.state?.length) &&
  !!cc.zip?.length &&
  !!cc.phone?.length &&
  !!cc.ccType &&
  !!cc.ccNickname?.length &&
  !!cc.ccLast4?.length &&
  !!cc.ccExp?.length &&
  !!cc.cvv?.length;

type UseCyberSource = {
  creditCard: BillingDTO;
  setCreditCard: (value: BillingDTO) => void;
  onSave: (number: string) => Promise<BillingDTO>;
  onCancel: () => void;
  onDelete: () => Promise<any>;
  makeDefault: () => Promise<BillingDTO>;
  validCreditCard: (value: NewBillingDTO) => boolean;
};

export default function useCyberSource(initial, otherUserId: number): UseCyberSource {
  const { userInfo } = useAuth();
  const [creditCard, setCreditCard] = React.useState<BillingDTO>(initial || defaultCC);
  const [userId] = React.useState<number>(otherUserId || userInfo.employeeUserId);

  const onSave = async (number: string) => {
    const { token } = await createCyberSourceToken(creditCard, number);
    return saveCreditCard(userId, { ...creditCard, ccToken: token }).then((res) => {
      setCreditCard(res);
      return res;
    });
  };

  const onCancel = () => {
    setCreditCard(initial || defaultCC);
  };

  const onDelete = async () => {
    return deleteCreditCard(userId, creditCard.userBillingId);
  };

  const makeDefault = async () => {
    return saveCreditCard(userId, { ...creditCard, isDefault: true });
  };

  return { creditCard, setCreditCard, onSave, onCancel, onDelete, makeDefault, validCreditCard };
}

export const getCCMonthsAway = (ccExp: string): number => {
  const split = ccExp.split("/");
  if (split.length > 1) {
    const now: Date = new Date();
    const exp: Date = lastDayOfMonth(new Date(`${split[0]}/1/${split[1]}`)) as Date;
    return isAfter(exp, now) ? getMonthsBetween(now, exp) : -1;
  } else {
    return 0;
  }
};

export const isCCExpired = (ccExp: string): boolean => {
  return getCCMonthsAway(ccExp) < 0;
};

export const getCCExpInfo = (ccExp: string, theme: ThemeType): { color: string; text: string } => {
  const monthsAway = getCCMonthsAway(ccExp);
  if (monthsAway < 0) {
    return { color: theme.palette.coreColorsMlbRed, text: "expired" };
  } else if (monthsAway < 6) {
    return { color: theme.palette.coreColorsNonTextRed, text: "expiring" };
  }
  return { color: theme.palette.onColorsOnSurface, text: "expires" };
};
