import { Button, Modal } from "best-common-react";
import React from "react";
import styled from "styled-components";
import CreditCardMappings from "../../constants/CreditCardMappings";
import { CreditCardIcon } from "./CreditCard";

const CardInfo = styled.div.attrs(() => ({
  className: "mt-2 p-2",
}))`
  border: 1px solid ${(props) => props.theme.palette.surfaceColorsSurfaceContainerHigh};
  background: ${(props) => props.theme.palette.surfaceColorsSurfaceContainerLow};
`;

const DeleteCreditCardModal = ({ show, ccType, ccNickname, ccLast4, onClose, onDelete, loading }) => (
  <>
    {show && (
      <Modal show={show}>
        <Modal.Header close={onClose}>Delete Credit Card</Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the following credit card?
          <CardInfo>
            <div className="d-flex align-items-center">
              <CreditCardIcon type={CreditCardMappings[ccType.ccTypeName]} />{" "}
              <span className="ms-2">
                {ccNickname} ending in {ccLast4}
              </span>
            </div>
          </CardInfo>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="me-2" onClick={onDelete} loading={loading}>
            Delete
          </Button>
          <Button variant="default" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </>
);

export default DeleteCreditCardModal;
