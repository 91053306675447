import {
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  formatDate,
  Column,
  Row,
  Search,
  Select,
  useLoading,
  ValueOpt,
  isBefore,
  StatsTeam,
} from "best-common-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminSchedule } from "../../../api/RequesTixApi";
import EventsTable from "../../../components/events/EventsTable";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { AdminGameDTO } from "../../../types/Game";
import { nextMonthStr, todayStr } from "../../../util/DateObjUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { getTeamOption } from "../../../util/TeamUtil";

const AdminEvents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamsOptions } = useDropdowns();
  const { loading, setLoadingNonBlocking } = useLoading();
  const [events, setEvents] = React.useState<AdminGameDTO[]>([]);
  const { startDate = todayStr, endDate = nextMonthStr, teamId, searchTerm = "" } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const selectedHomeTeam = getTeamOption(teamId, teamsOptions);

  const updateUrl = ({ startDate, endDate, teamId, searchTerm }) => {
    updateRouteParams(navigate, RouteConstants.ADMIN.EVENTS, {
      startDate: formatDate(startDate, DATE_FORMATTER_STR),
      endDate: formatDate(endDate, DATE_FORMATTER_STR),
      teamId,
      searchTerm,
    });
  };

  const updateDate = (dateRange: DateRange<Date>) => {
    updateUrl({
      startDate: formatDate(dateRange.start, DATE_FORMATTER_STR),
      endDate: formatDate(dateRange.end, DATE_FORMATTER_STR),
      teamId,
      searchTerm,
    });
  };

  const fetchEvents = async (startDate: string, endDate: string, teamId?: number) => {
    try {
      if (isBefore(startDate, endDate)) {
        setLoadingNonBlocking(true);
        const res: AdminGameDTO[] = await getAdminSchedule(teamId, null, startDate, endDate);
        setEvents(res);
      }
    } catch (e) {
      console.error(e);
      setEvents([]);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    void fetchEvents(startDate, endDate, teamId);
  }, [startDate, endDate, teamId]);

  return (
    <Card>
      <Card.Header>Events</Card.Header>
      <Card.Body>
        <Row>
          <Column width={4}>
            <DateRangeInput id="event-dates" label="Event Date" value={dateRange} onChange={updateDate} />
          </Column>
          <Column width={4}>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={selectedHomeTeam}
              onChange={(team: ValueOpt<StatsTeam>) =>
                updateUrl({ teamId: team.value.id, startDate, endDate, searchTerm })
              }
            />
          </Column>
          <Column width={4}>
            <Search
              id="search-box"
              label="Search Events"
              placeholder="begin typing to filter events"
              value={searchTerm}
              onChange={(value) => updateUrl({ teamId, startDate, endDate, searchTerm: value })}
            />
          </Column>
        </Row>
        <EventsTable values={events} searchTerm={searchTerm} location={location} loading={loading} />
      </Card.Body>
    </Card>
  );
};

export default AdminEvents;
