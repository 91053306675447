export const columnWidths = {
  expansion: 35,
  edit: 35,
  status: 155,
  completed: 35,
  requester: 150,
  recipient: 150,
  department: 200,
  jobTitle: 250,
  quantity: 75,
  failedRequests: 175,
  category: 85,
  payBy: 80,
  surroundingGames: 40,
  comments: 60,
  project: 140,
  business: 140,
  submitted: 175,
  seats: 200,
};

export const minWidth = Object.values(columnWidths).reduce((a, b) => a + b, 0);

export const OPEN = 1;
export const IN_REVIEW = 2;
export const APPROVED = 3;
export const DENIED = 4;

export const PAY_BY_COMP = "Comp";
export const PAY_BY_CHARGE = "Card";
export const PAY_BY_DEPT = "Dept";

export const cols = [
  {
    name: "",
    key: "expansion",
    width: columnWidths["expansion"],
  },
  {
    name: "",
    key: "edit",
    width: columnWidths["edit"],
  },
  {
    name: "Status",
    key: "status",
    width: columnWidths["status"],
    sortable: true,
  },
  {
    name: "",
    key: "completed",
    width: columnWidths["completed"],
  },
  {
    name: "Requester",
    key: "requester",
    width: columnWidths["requester"],
    sortable: true,
  },
  {
    name: "Job Title",
    key: "jobTitle",
    width: columnWidths["jobTitle"],
    sortable: true,
  },
  {
    name: "Recipient",
    key: "recipient",
    width: columnWidths["recipient"],
    sortable: true,
  },
  {
    name: "Department",
    key: "department",
    width: columnWidths["department"],
    sortable: true,
  },
  {
    name: "Qty",
    key: "quantity",
    width: columnWidths["quantity"],
    cellClass: "text-center",
    sortable: true,
  },
  {
    name: "Seats",
    key: "seats",
    width: columnWidths["seats"],
    sortable: false,
  },
  {
    name: "Failed Requests",
    key: "failedRequests",
    width: columnWidths["failedRequests"],
    cellClass: "text-center",
    sortable: true,
  },
  {
    name: "Category",
    key: "category",
    width: columnWidths["category"],
    sortable: true,
  },
  {
    name: "Pay By",
    key: "payBy",
    width: columnWidths["payBy"],
    cellClass: "text-center",
    sortable: true,
  },
  {
    name: "",
    key: "surroundingGames",
    width: columnWidths["surroundingGames"],
  },
  {
    name: "",
    key: "comments",
    width: columnWidths["comments"],
  },
  {
    name: "Project",
    key: "project",
    width: columnWidths["project"],
    sortable: true,
  },
  {
    name: "Business",
    key: "business",
    width: columnWidths["business"],
    sortable: true,
  },
  {
    name: "Request Submitted",
    key: "submitted",
    width: columnWidths["submitted"],
    sortable: true,
  },
];

export const statusColors = {};
