import React from "react";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { RequestTypeDesc } from "../../../types/RequestType";
import { SeasonTypeCode } from "../../../types/Season";
import { getSeasonTypeForGame } from "../../../util/SeasonUtil";
import SeasonSelect from "../card/SeasonSelect";
import { StatsGame } from "best-common-react";

type TicketRequestSeasonSelectProps = {
  game: StatsGame;
  userId: number;
  setAllotments: (value: UserAllotmentCountDTO[]) => void;
  type: RequestTypeDesc;
};

const TicketRequestSeasonSelect: React.FC<TicketRequestSeasonSelectProps> = ({ game, userId, setAllotments, type }) => {
  const seasonType: SeasonTypeCode = getSeasonTypeForGame(game);
  return (
    <SeasonSelect
      userId={userId}
      year={game.season}
      seasonType={seasonType}
      setSeasonType={() => {}}
      type={type}
      setAllotments={setAllotments}
    />
  );
};

export default TicketRequestSeasonSelect;
