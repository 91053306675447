import { getLocalStorageItem, setLocalStorageItem, isBefore, StatsTeam } from "best-common-react";
import React, { createContext, useEffect, useState } from "react";
import {
  getActiveSeason,
  getAllDayOfRequestStatuses,
  getAllSeriesTypes,
  getAllSuiteRequestStatuses,
  getCacheRefreshTime,
  getCardTypes,
  getRequestCategories,
  getRequestFulfillmentTypes,
  getRequestStates,
  getRequestTypes,
  getRoles,
  getSeasons,
  getStates,
  getTeams,
  getUserBillingCountries,
  getVenueDeliveryMethodOptions,
} from "../api/RequesTixApi";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import MonthConstants from "../constants/MonthConstants";
import { CreditCardType } from "../types/CreditCardType";
import { Month } from "../types/Months";
import { SeriesTypeDTO } from "../types/PurchaseOpportunity";
import { RequestCategory, RequestFulfillmentType, RequestState, RequestType } from "../types/RequestType";
import { Role } from "../types/Role";
import { Season } from "../types/Season";
import { State } from "../types/State";
import { SuiteRequestStatus } from "../types/SuiteRequest";
import { DayOfRequestStatus } from "../types/TodaysTickets";
import { UserBillingCountry } from "../types/UserBilling";
import { VenueDeliveryMethodOptions } from "../types/Venue";
import { getUserInfo } from "../util/UserUtil";
import { useAuth } from "./AuthContext";

type MetadataContextType = {
  refreshMetadata: (refresh: boolean) => Promise<any>;
  onLogin: any;
  cacheTime: Date;
  teams: StatsTeam[];
  activeSeason: Season;
  seasons: Season[];
  years: number[];
  roles: Role[];
  states: State[];
  cardTypes: CreditCardType[];
  creditYears: number[];
  requestTypes: RequestType[];
  requestFulfillmentTypes: RequestFulfillmentType[];
  requestStates: RequestState[];
  requestCategories: RequestCategory[];
  months: Month[];
  venueDeliveryMethods: VenueDeliveryMethodOptions[];
  billingCountries: UserBillingCountry[];
  suiteRequestStatuses: SuiteRequestStatus[];
  seriesTypes: SeriesTypeDTO[];
  dayOfRequestStatuses: DayOfRequestStatus[];
};

const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

export function MetadataProvider(props) {
  const { onLogin } = useAuth();
  const [cacheTime, setCacheTime] = useState<Date>();
  const [teams, setTeams] = useState<StatsTeam[]>([]);
  const [activeSeason, setActiveSeason] = useState<Season>({} as Season);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cardTypes, setCardTypes] = useState<CreditCardType[]>([]);
  const [creditYears, setCreditYears] = useState<number[]>([]);
  const [requestTypes, setRequestTypes] = useState<RequestType[]>([]);
  const [requestFulfillmentTypes, setRequestFulfillmentTypes] = useState<RequestFulfillmentType[]>([]);
  const [requestStates, setRequestStates] = useState<RequestState[]>([]);
  const [requestCategories, setRequestCategories] = useState<RequestCategory[]>([]);
  const [months, setMonths] = useState<Month[]>([]);
  const [venueDeliveryMethods, setVenueDeliveryMethods] = useState<VenueDeliveryMethodOptions[]>([]);
  const [billingCountries, setBillingCountries] = useState<UserBillingCountry[]>([]);
  const [suiteRequestStatuses, setSuiteRequestStatuses] = useState<SuiteRequestStatus[]>([]);
  const [seriesTypes, setSeriesTypes] = useState<SeriesTypeDTO[]>([]);
  const [dayOfRequestStatuses, setDayOfRequestStatus] = useState<DayOfRequestStatus[]>([]);

  const checkCacheTime = async () => {
    const lastCacheTime = getLocalStorageItem<Date>(LocalStorageConstants.LAST_CACHE_TIME);
    const refresh = !lastCacheTime || isBefore(new Date(lastCacheTime), cacheTime);
    if (refresh) {
      setLocalStorageItem(LocalStorageConstants.LAST_CACHE_TIME, new Date());
      await getUserInfo();
      await onLogin();
    }
    await refreshMetadata(refresh);
  };

  const getCacheRefresh = async () => {
    const data = await getCacheRefreshTime();
    if (data) {
      setCacheTime(new Date(data as string));
    }
  };

  const getActiveSeasonsMetadata = async () => {
    const data = await getActiveSeason();
    setActiveSeason(data);
  };

  const refreshMetadata = async (refresh: boolean) => {
    const promises: Promise<any>[] = [
      getTeamsMetadata(refresh),
      getSeasonsMetadata(refresh),
      getRolesMetadata(refresh),
      getStatesMetadata(refresh),
      getCardTypesMetadata(refresh),
      getCreditYearsMetadata(refresh),
      getRequestTypesMetadata(refresh),
      getRequestFulfillmentTypesMetadata(refresh),
      getMonthsMetadata(),
      getRequestStatesMetadata(refresh),
      getRequestCategoriesMetadata(refresh),
      getVenueDeliveryMethods(refresh),
      getBillingCountriesMetadata(refresh),
      getSuiteRequestStatusesMetadata(refresh),
      getSeriesTypes(refresh),
      getTodaysTicketsStatuses(refresh),
    ];
    await Promise.all(promises);
  };

  const getTeamsMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<StatsTeam[]>(LocalStorageConstants.TEAMS);
    if (localStorage && !refresh) {
      setTeams(localStorage);
    } else {
      const res: StatsTeam[] = await getTeams();
      setTeams(res);
      setLocalStorageItem(LocalStorageConstants.TEAMS, res);
    }
  };

  const getSeasonsMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<Season[]>(LocalStorageConstants.SEASONS);
    if (localStorage && !refresh) {
      setSeasons(localStorage);
      setYears(getLocalStorageItem<number[]>(LocalStorageConstants.YEARS));
    } else {
      const res: Season[] = await getSeasons();
      const values = res.sort((s1, s2) => {
        if (s1.year > s2.year) {
          return 1;
        } else if (s1.year < s2.year) {
          return -1;
        } else {
          const id1 = s1.seasonType.seasonTypeId;
          const id2 = s2.seasonType.seasonTypeId;
          if (id1 > id2) {
            return 1;
          } else if (id1 < id2) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      setSeasons(values);
      const yearValues: number[] = Array.from(new Set(values.map((season) => season.year)));
      setYears(yearValues);
      setLocalStorageItem(LocalStorageConstants.SEASONS, values);
      setLocalStorageItem(LocalStorageConstants.YEARS, yearValues);
    }
  };

  const getRolesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<Role[]>(LocalStorageConstants.ROLES);
    if (localStorage && !refresh) {
      setRoles(localStorage);
    } else {
      const res: Role[] = await getRoles();
      setRoles(res);
      setLocalStorageItem(LocalStorageConstants.ROLES, res);
    }
  };

  const getStatesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<State[]>(LocalStorageConstants.STATES);
    if (localStorage && !refresh) {
      setStates(localStorage);
    } else {
      const res: State[] = await getStates();
      setStates(res);
      setLocalStorageItem(LocalStorageConstants.STATES, res);
    }
  };

  const getCardTypesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<CreditCardType[]>(LocalStorageConstants.CARD_TYPES);
    if (localStorage && !refresh) {
      setCardTypes(localStorage);
    } else {
      const res: CreditCardType[] = await getCardTypes();
      setCardTypes(res);
      setLocalStorageItem(LocalStorageConstants.CARD_TYPES, res);
    }
  };

  const getCreditYearsMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<number[]>(LocalStorageConstants.CARD_YEARS);
    if (localStorage && !refresh) {
      setCreditYears(localStorage);
    } else {
      const year = new Date().getFullYear();
      const years = [];
      for (let i = year; i < year + 10; i++) {
        years.push(i);
      }
      setCreditYears(years);
      setLocalStorageItem(LocalStorageConstants.CARD_YEARS, years);
    }
  };

  const getRequestTypesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<RequestType[]>(LocalStorageConstants.REQUEST_TYPES);
    if (localStorage && !refresh) {
      setRequestTypes(localStorage);
    } else {
      const res: RequestType[] = await getRequestTypes();
      setRequestTypes(res);
      setLocalStorageItem(LocalStorageConstants.REQUEST_TYPES, res);
    }
  };

  const getRequestFulfillmentTypesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<RequestFulfillmentType[]>(LocalStorageConstants.REQUEST_FULFILLMENT_TYPES);
    if (localStorage && !refresh) {
      setRequestFulfillmentTypes(localStorage);
    } else {
      const res: RequestFulfillmentType[] = await getRequestFulfillmentTypes();
      setRequestFulfillmentTypes(res);
      setLocalStorageItem(LocalStorageConstants.REQUEST_FULFILLMENT_TYPES, res);
    }
  };

  const getMonthsMetadata = async () => {
    setMonths(Object.values(MonthConstants) as Month[]);
  };

  const getRequestStatesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<RequestState[]>(LocalStorageConstants.REQUEST_STATES);
    if (localStorage && !refresh) {
      setRequestStates(localStorage);
    } else {
      const res: RequestState[] = await getRequestStates();
      setRequestStates(res);
      setLocalStorageItem(LocalStorageConstants.REQUEST_STATES, res);
    }
  };

  const getRequestCategoriesMetadata = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<RequestCategory[]>(LocalStorageConstants.REQUEST_CATEGORIES);
    if (localStorage && !refresh) {
      setRequestCategories(localStorage);
    } else {
      const res: RequestCategory[] = await getRequestCategories();
      setRequestCategories(res);
      setLocalStorageItem(LocalStorageConstants.REQUEST_CATEGORIES, res);
    }
  };

  const getVenueDeliveryMethods = async (refresh: boolean) => {
    const localStorage = getLocalStorageItem<VenueDeliveryMethodOptions[]>(
      LocalStorageConstants.VENUE_DELIVERY_METHODS
    );
    if (localStorage && !refresh) {
      setVenueDeliveryMethods(localStorage);
    } else {
      const res: VenueDeliveryMethodOptions[] = await getVenueDeliveryMethodOptions();
      setVenueDeliveryMethods(res);
      setLocalStorageItem(LocalStorageConstants.VENUE_DELIVERY_METHODS, res);
    }
  };

  const getBillingCountriesMetadata = async (refresh: boolean): Promise<void> => {
    const localStorage = getLocalStorageItem<UserBillingCountry[]>(LocalStorageConstants.USER_BILLING_COUNTRIES);
    if (localStorage && !refresh) {
      setBillingCountries(localStorage);
    } else {
      const res: UserBillingCountry[] = await getUserBillingCountries();
      setBillingCountries(res);
      setLocalStorageItem(LocalStorageConstants.USER_BILLING_COUNTRIES, res);
    }
  };

  const getSuiteRequestStatusesMetadata = async (refresh: boolean): Promise<void> => {
    const localStorage = getLocalStorageItem<SuiteRequestStatus[]>(LocalStorageConstants.SUITE_REQUEST_STATUSES);
    if (localStorage && !refresh) {
      setSuiteRequestStatuses(localStorage);
    } else {
      const res: SuiteRequestStatus[] = await getAllSuiteRequestStatuses();
      setSuiteRequestStatuses(res);
      setLocalStorageItem(LocalStorageConstants.SUITE_REQUEST_STATUSES, res);
    }
  };

  const getSeriesTypes = async (refresh: boolean): Promise<void> => {
    const localStorage = getLocalStorageItem<SeriesTypeDTO[]>(LocalStorageConstants.SERIES_TYPES);
    if (localStorage && !refresh) {
      setSeriesTypes(localStorage);
    } else {
      const res: SeriesTypeDTO[] = await getAllSeriesTypes();
      setSeriesTypes(res);
      setLocalStorageItem(LocalStorageConstants.SERIES_TYPES, res);
    }
  };

  const getTodaysTicketsStatuses = async (refresh: boolean): Promise<void> => {
    const localStorage = getLocalStorageItem<DayOfRequestStatus[]>(LocalStorageConstants.TODAYS_TICKETS_STATUSES);
    if (localStorage && !refresh) {
      setDayOfRequestStatus(localStorage);
    } else {
      const res: DayOfRequestStatus[] = await getAllDayOfRequestStatuses();
      setDayOfRequestStatus(res);
      setLocalStorageItem(LocalStorageConstants.TODAYS_TICKETS_STATUSES, res);
    }
  };

  useEffect(() => {
    void getCacheRefresh();
    void getActiveSeasonsMetadata();
  }, []);

  useEffect(() => {
    if (cacheTime) {
      void checkCacheTime();
    }
  }, [cacheTime]);

  return (
    <MetadataContext.Provider
      value={{
        refreshMetadata,
        teams,
        activeSeason,
        seasons,
        years,
        roles,
        states,
        cardTypes,
        creditYears,
        requestTypes,
        requestFulfillmentTypes,
        months,
        requestStates,
        requestCategories,
        venueDeliveryMethods,
        billingCountries,
        suiteRequestStatuses,
        seriesTypes,
        dayOfRequestStatuses,
      }}
      {...props}
    />
  );
}

export function useMetadata(): MetadataContextType {
  const context: MetadataContextType | undefined = React.useContext(MetadataContext);
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return context;
}
