import { BCR, BCRContentContainer, BCRSuspense, Traditional } from "best-common-react";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
import PermissionDenied from "./AccessDenied";
import AuthenticatedApp from "./AuthenticatedApp";
import { useAuth } from "./contexts/AuthContext";
import UnAuthenticatedApp from "./UnAuthenticatedApp";
import styled, { createGlobalStyle } from "styled-components";

type ContainerProps = {
  loggedIn: boolean;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: calc(100% - ${(props) => (props.loggedIn ? "69px" : "50px")});
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const GlobalStyles = createGlobalStyle``;

const AppCheck: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, permissionError, initialized } = useAuth();
  const [hasRedirected, setHasRedirected] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>("");

  useEffect(() => {
    if (!pathname.length) {
      setPathname(location.pathname + location.search);
    }
    if (loggedIn && !hasRedirected && !!pathname.length) {
      navigate(pathname);
      setHasRedirected(true);
    }
  }, [loggedIn, location.pathname]);

  return (
    <Container loggedIn={loggedIn}>
      <GlobalStyles />
      <BCRContentContainer>
        {initialized ? (
          <>{permissionError ? <PermissionDenied /> : loggedIn ? <AuthenticatedApp /> : <UnAuthenticatedApp />}</>
        ) : (
          <UnAuthenticatedApp />
        )}
      </BCRContentContainer>
    </Container>
  );
};

const App: React.FC = () => {
  return (
    <BCR theme={Traditional}>
      <React.Suspense fallback={<BCRSuspense />}>
        <Router>
          <AppCheck />
        </Router>
      </React.Suspense>
    </BCR>
  );
};

export default App;
