import {
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  Column,
  getRawDate,
  Row,
  Search,
  Select,
  sort,
  Typography,
  ValueOpt,
} from "best-common-react";
import * as H from "history";
import React, { useEffect, useMemo, useState } from "react";
import { graphQl } from "../../../api/RequesTixApi";
import { APPROVED, DENIED } from "../../../constants/RequestTableConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { Project } from "../../../types/Project";
import { RequestState } from "../../../types/RequestType";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { getStatusOption } from "../../../util/DropdownUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import ProjectRequestsAllProjects from "./ProjectRequestsAllProjects";
import ProjectRequestsByProject from "./ProjectRequestsByProject";

const createQuery = () => `{
  getProjectsForUser{
    projectId,
    name,
    description,
    active
  }
}
`;

export type ProjectUserTicketRequestDTO = UserTicketRequestDTO & {
  homeTeam: string;
  statusName: string;
  gameDate: Date;
  location: H.Location;
};

const ProjectRequestsReport = ({ reportData, setReportData, location, setCanExport }: ReportHandlerTypeProps) => {
  const { search, fulfillmentTypeId, statusId, projectId, startDate, endDate } = reportData;
  const { isAdmin } = useAuth();
  const [projectOptions, setProjectOptions] = useState<ValueOpt<Project>[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });
  const { requestStateOptions, requestFulfillmentTypesOptions, getOptionByValue } = useDropdowns();

  const fulfillmentTypesOptions: ValueOpt<number>[] = useMemo(
    () => [{ label: "All", value: -1 }, ...requestFulfillmentTypesOptions],
    [requestFulfillmentTypesOptions]
  );

  const validStateIds = [APPROVED, DENIED];
  const stateOptions: ValueOpt<RequestState>[] = useMemo(() => {
    const finalOpts = requestStateOptions.filter((opt) => validStateIds.includes(opt.value.requestStateId));
    return [{ label: "All", value: {} }, ...finalOpts];
  }, [requestStateOptions, validStateIds]);

  const isAllProject = useMemo(() => !projectId, [projectId]);
  const isApproved = useMemo(() => statusId && statusId * 1 === APPROVED, [statusId]);
  const selectedProject = useMemo(() => {
    if (!!projectId) {
      return projectOptions.find((opt) => opt.value.projectId === projectId * 1);
    } else if (isAdmin) {
      return projectOptions.find((opt) => {
        if (projectId) {
          return opt.value.projectId === projectId;
        } else {
          return opt.value.projectId === undefined;
        }
      });
    } else {
      return projectOptions.length > 0 ? projectOptions[0] : null;
    }
  }, [projectId, projectOptions, isAdmin]);

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  useEffect(() => {
    const queryObj = {
      query: createQuery(),
      variables: null,
      operationName: null,
    };
    graphQl(queryObj).then((data) => {
      if (data) {
        const projectOptions = sort(
          data.getProjectsForUser.map((d) => ({
            label: d.name,
            value: d,
          })),
          "label",
          1
        );
        if (isAdmin) {
          projectOptions.unshift({ label: "All Projects", value: {} });
        }

        setProjectOptions(projectOptions);
      }
    });
  }, []);

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    setDateRange(newDate);
    setReportData({
      ...reportData,
      startDate: formatDate(newDate.start, DATE_FORMATTER_STR),
      endDate: formatDate(newDate.end, DATE_FORMATTER_STR),
    });
  }, [startDate, endDate]);

  return (
    <>
      <Row>
        <Column width={4}>
          <Select
            id="project"
            label="Project"
            value={selectedProject}
            options={projectOptions}
            onChange={(value) => onChange("projectId", value.value.projectId)}
          />
        </Column>
        <Column width={4}>
          <DateRangeInput
            id="request-date"
            label="Request Date"
            value={dateRange}
            onChange={updateDate}
            maxRange={{
              months: 3,
            }}
            withPortal
            gutterBottom
          />
        </Column>
        <Column width={4}>
          <Search
            id="search"
            label="Search Projects"
            value={search}
            onChange={(value) => {
              onChange("search", value);
            }}
            placeholder="begin typing to filter"
          />
        </Column>
      </Row>
      {!isAllProject && (
        <>
          <Column width={4}>
            <Select
              id="status"
              label="Status"
              options={stateOptions}
              value={getStatusOption(statusId, stateOptions)}
              onChange={(value) => {
                const statusId = value.value.requestStateId;
                if (statusId === APPROVED) {
                  onChange("statusId", statusId);
                } else {
                  setReportData({ ...reportData, fulfillmentTypeId: null, statusId: statusId });
                }
              }}
            />
          </Column>
          {isApproved && (
            <Column width={4}>
              <Select
                id="fulfillment"
                label="Fulfillment"
                options={fulfillmentTypesOptions}
                value={getOptionByValue(fulfillmentTypesOptions, fulfillmentTypeId ? fulfillmentTypeId * 1 : {})}
                onChange={(value) => {
                  const val = typeof value.value === "object" ? null : value.value;
                  onChange("fulfillmentTypeId", val);
                }}
              />
            </Column>
          )}
          <div className="mt-2">
            <Typography variant="h6">Project Description</Typography>
            <Typography variant="body2">
              {selectedProject?.value?.description ? selectedProject.value.description : ""}
            </Typography>
          </div>
        </>
      )}
      {isAllProject ? (
        <ProjectRequestsAllProjects
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      ) : (
        <ProjectRequestsByProject
          reportData={reportData}
          setCanExport={setCanExport}
          setReportData={setReportData}
          location={location}
        />
      )}
    </>
  );
};

export default ProjectRequestsReport;
