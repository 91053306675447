import {
  areObjectsEqual,
  Attribute,
  Button,
  Card,
  DateRange,
  DateRangeInput,
  DesktopTabletView,
  Column,
  Hint,
  Input,
  MobileView,
  Row,
  Section,
  Select,
  sort,
  Typography,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import {
  getVenue,
  getVenueDayOf,
  getVenueOverride,
  saveVenueOverride,
  updateVenue,
  updateVenueDayOf,
} from "../../../api/RequesTixApi";
import AdminFooter from "../../../components/admin/AdminFooter";
import PersonalBusinessCutoff from "../../../components/cutoffs/PersonalBusinessCutoff";
import TicketsLocationEditing from "../../../components/tickets/TicketsLocationEditing";
import DeliveryMethodsDnD from "../../../components/venue/DeliveryMethodsDnD";
import DeliveryMethodsMobileDnD from "../../../components/venue/DeliveryMethodsMobileDnD";
import RouteConstants from "../../../constants/RouteConstants";
import { useAdminYear } from "../../../contexts/AdminYearContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { CreditCardType } from "../../../types/CreditCardType";
import { VenueDayOfDTO } from "../../../types/TodaysTickets";
import { VenueCutoffOverride, VenueDeliveryMethod, VenueDTO } from "../../../types/Venue";
import { differenceWith } from "best-common-react";
import { useNavigate, useParams } from "react-router-dom";

type AdminEditVenueRouteParams = {
  venueId: string;
};

const AdminEditVenue = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { venueId: venueIdParam } = useParams<AdminEditVenueRouteParams>();
  const venueId = parseInt(venueIdParam);
  const { cardTypes } = useMetadata();
  const { yearsOptions } = useDropdowns();
  const [selectedYear, setSelectedYear] = useAdminYear();
  const [hasEdited, setHasEdited] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [venue, setVenue] = useState<VenueDTO>({
    id: venueId,
    name: "",
    city: "",
    state: "",
    country: "",
    acceptedBillings: [],
    deliveryMethods: [],
    seasonTickets: [],
    dayOfInclusions: [],
    blackoutStartDate: undefined,
    blackoutEndDate: undefined,
  });
  const [canSave, setCanSave] = useState(true);
  const [selectedCCs, setSelectedCCs] = useState<ValueOpt<CreditCardType>[]>([]);
  const [cardTypeOptions, setCardTypeOptions] = useState<ValueOpt<CreditCardType>[]>([]);
  const [cutoffOverride, setCutoffOverride] = useState<VenueCutoffOverride>();
  const [hoursBefore, setHoursBefore] = useState<string | number | undefined>();
  const [dateRange, setDateRange] = useState<DateRange<Date>>({ start: undefined, end: undefined });

  const onAcceptedBillingChange = (value) => {
    setHasEdited(true);
    const newAcceptedBillings = value ? value.map((cc) => cc.value) : [];
    setVenue({ ...venue, acceptedBillings: newAcceptedBillings });
  };

  const addAllBillings = () => {
    setHasEdited(true);
    const newAcceptedBillings = [
      ...venue.acceptedBillings,
      ...differenceWith(cardTypes, venue.acceptedBillings, areObjectsEqual),
    ];
    setVenue({ ...venue, acceptedBillings: newAcceptedBillings });
  };

  const goBack = () => {
    navigate(RouteConstants.ADMIN.VENUES);
  };

  const onSave = async () => {
    const promises: Promise<any>[] = [
      saveVenueOverride(venueId, cutoffOverride),
      updateVenueDayOf({
        venueId: venueId,
        hoursBefore: parseInt(hoursBefore as string),
      }),
      updateVenue(venue),
    ];
    await Promise.all(promises);
    goBack();
  };

  const onDeliverMethodAdd = () => {
    setHasEdited(true);
    const newLocation: VenueDeliveryMethod = {
      venueId,
      year: selectedYear.value,
      rank: venue.deliveryMethods.length + 1,
      description: "",
      isDeleted: false,
    };
    setVenue({ ...venue, deliveryMethods: [...venue.deliveryMethods, newLocation] });
  };

  const onDeliverMethodReorder = (start, end) => {
    setHasEdited(true);
    const temp = venue.deliveryMethods;
    const [removed] = temp.splice(start, 1);
    temp.splice(end, 0, removed);
    temp.forEach((location, index) => (location.rank = index + 1));
    setVenue({ ...venue, deliveryMethods: temp });
  };

  const onDeliveryMethodChange = (index, field, value) => {
    setHasEdited(true);
    const temp = venue.deliveryMethods;
    temp[index][field] = value;
    setVenue({ ...venue, deliveryMethods: temp });
  };

  const onDeliveryMethodDelete = (toDelete) => {
    setHasEdited(true);
    setVenue({ ...venue, deliveryMethods: venue.deliveryMethods.filter((method) => method !== toDelete) });
  };

  const setBlackoutDateRange = (dateRange: DateRange<Date>) => {
    const valid = (!dateRange.start && !dateRange.end) || (!!dateRange.start && !!dateRange.end);
    setValidDate(valid);
    setHasEdited(true);
    setVenue({ ...venue, blackoutStartDate: dateRange.start, blackoutEndDate: dateRange.end });
  };

  const setSeasonTickets = (tickets) => {
    setHasEdited(true);
    setVenue({ ...venue, seasonTickets: tickets });
  };

  const setDayOfInclusions = (tickets) => {
    setHasEdited(true);
    setVenue({ ...venue, dayOfInclusions: tickets });
  };

  const changeCutoffOverride = (override) => {
    setHasEdited(true);
    setCutoffOverride(override);
  };

  const addCutoffOverride = () => {
    setHasEdited(true);
    setCutoffOverride({
      venueId: venueId,
      personalCutoffDate: null,
      personalCutoffHoursBefore: 5,
      personalCutoffUseDate: false,
      businessCutoffDate: null,
      businessCutoffHoursBefore: 5,
      businessCutoffUseDate: false,
    });
  };

  const getVenueCall = async (venueId, year) => {
    const res: VenueDTO = await getVenue(venueId, year);
    setVenue({
      ...res,
      deliveryMethods: sort(res.deliveryMethods, "rank"),
      acceptedBillings: sort(res.acceptedBillings, "ccTypeId"),
    });
  };

  const getVenueOverrideCall = async (venueId) => {
    const res: VenueCutoffOverride = await getVenueOverride(venueId);
    if (!!res) {
      setCutoffOverride(res);
    }
  };

  const getVenueDayOfCall = async (venueId) => {
    const res: VenueDayOfDTO = await getVenueDayOf(venueId);
    const value = res?.hoursBefore === undefined ? 24 : res.hoursBefore;
    setHoursBefore(value);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const promises: Promise<any>[] = [
        getVenueCall(venueId, selectedYear.value),
        getVenueOverrideCall(venueId),
        getVenueDayOfCall(venueId),
      ];
      await Promise.all(promises);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (venueId && selectedYear.value) {
      fetchData();
    }
  }, [venueId, selectedYear]);

  useEffect(() => {
    const invalidDeliveryMethods: VenueDeliveryMethod[] = venue.deliveryMethods.filter(
      (location: VenueDeliveryMethod) => !location.deliveryMethod || !location.description?.length
    );
    setCanSave(!invalidDeliveryMethods.length && validDate && hoursBefore !== undefined);
  }, [venue, validDate]);

  useEffect(() => {
    setSelectedCCs(venue.acceptedBillings.map((cc) => ({ label: cc.ccTypeName, value: cc })));
  }, [venue.acceptedBillings]);

  useEffect(() => {
    const diff = differenceWith(cardTypes, venue.acceptedBillings, areObjectsEqual);
    setCardTypeOptions(diff.map((cc) => ({ label: cc.ccTypeName, value: cc })));
  }, [venue.acceptedBillings, cardTypes]);

  useEffect(() => {
    setDateRange({
      start: !!venue.blackoutStartDate ? new Date(venue.blackoutStartDate) : undefined,
      end: !!venue.blackoutEndDate ? new Date(venue.blackoutEndDate) : undefined,
    });
  }, [venue.blackoutEndDate, venue.blackoutStartDate]);

  return (
    <Card>
      <Card.Header>{venue.name}</Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <Column width={3}>
            <Select
              id="years-dropdown"
              label="Year"
              options={yearsOptions}
              value={selectedYear}
              onChange={setSelectedYear}
              gutterBottom
            />
          </Column>
          <Column width={3}>
            <Attribute header="Location" value={venue.state ? `${venue.city}, ${venue.state}` : venue.city} />
          </Column>
          <Column width={3}>
            <Select
              id="accepted-billings-select"
              label={
                <div className="d-flex align-items-center">
                  <div>Accepted Credit Cards</div>
                  <Button variant="link" className="ms-2" onClick={addAllBillings}>
                    Add All
                  </Button>
                </div>
              }
              isMulti
              options={cardTypeOptions}
              value={selectedCCs}
              onChange={onAcceptedBillingChange}
              gutterBottom
            />
          </Column>
        </Row>
        <Section sectionKey="delivery-methods" header="Delivery Methods">
          <DesktopTabletView>
            <DeliveryMethodsDnD
              deliveryMethods={venue.deliveryMethods}
              onReorder={onDeliverMethodReorder}
              onChange={onDeliveryMethodChange}
              onDelete={onDeliveryMethodDelete}
            />
          </DesktopTabletView>
          <MobileView>
            <DeliveryMethodsMobileDnD
              deliveryMethods={venue.deliveryMethods}
              onReorder={onDeliverMethodReorder}
              onChange={onDeliveryMethodChange}
              onDelete={onDeliveryMethodDelete}
            />
          </MobileView>
          <div className="d-flex justify-content-end mt-2">
            <Button onClick={onDeliverMethodAdd}>Add Location</Button>
          </div>
        </Section>
        <Section sectionKey="request-blackouts" header="Request Blackouts">
          <Row>
            <Column width={4}>
              <DateRangeInput
                id="blackout-dates"
                label="Blackout Dates"
                value={dateRange}
                onChange={setBlackoutDateRange}
                gutterBottom
                clearable
              />
            </Column>
          </Row>
        </Section>
        <Section sectionKey="season-tickets" header="Season Tickets">
          <TicketsLocationEditing venueId={venueId} tickets={venue.seasonTickets} setTickets={setSeasonTickets} />
        </Section>
        <Section sectionKey="cutoff-override" header="Cutoff Override">
          {!!cutoffOverride && (
            <Card className="px-1">
              <Card.Body>
                <PersonalBusinessCutoff override={cutoffOverride} setOverride={changeCutoffOverride} />
              </Card.Body>
            </Card>
          )}
          {!cutoffOverride && <Button onClick={addCutoffOverride}>Add Cutoff Override</Button>}
        </Section>
        <Section sectionKey="todays-tickets" header="Today's Tickets">
          <Row>
            <Column width={4}>
              <Input
                id="hoursBefore"
                label="Hours Before to allow request"
                type="number"
                value={hoursBefore}
                onChange={(value) => {
                  setHoursBefore(value);
                  setHasEdited(true);
                }}
                placeholder="hours before..."
              />
            </Column>
          </Row>
          <Row className="mt-2">
            <Column>
              <Typography variant="h6">Tickets to include in Today's Tickets</Typography>
              <Hint>* can be used as a wildcard to include all that match that field</Hint>
              <TicketsLocationEditing
                venueId={venueId}
                tickets={venue.dayOfInclusions}
                setTickets={setDayOfInclusions}
              />
            </Column>
          </Row>
        </Section>
      </Card.Body>
      <Card.Footer>
        <AdminFooter canSave={canSave} hasEdited={hasEdited} onCancel={goBack} onSave={onSave} />
      </Card.Footer>
    </Card>
  );
};

export default AdminEditVenue;
