import {
  Button,
  Card,
  Column,
  Row,
  Search,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getRequestsByGamePk } from "../../../api/RequesTixApi";
import AdminRequestGame from "../../../components/requests/admin/AdminRequestGame";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { ReportComplete, RequestCategory, RequestState } from "../../../types/RequestType";
import { getCategoryOption, getCompleteOption, getStatusOption } from "../../../util/DropdownUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { requestsFreeTextSearch } from "../../../util/TicketRequestUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AdminRequestByGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { gamePk } = useParams();
  const [data, setData] = useState({
    game: null,
    requests: [],
    totalRequests: 0,
  });
  const [filteredData, setFilteredData] = useState([]);

  const { requestCategoryOptions, requestStateOptions, completeOptions } = useDropdowns();

  const { statusId, categoryId, completeId, search } = useRouteParams(location.search);

  const category = getCategoryOption(categoryId, requestCategoryOptions);
  const status = getStatusOption(statusId, requestStateOptions);
  const complete = getCompleteOption(completeId, completeOptions);

  const updateUrl = ({ statusId, categoryId, completeId, search }) => {
    const url = urlReplace(RouteConstants.ADMIN.REQUESTS_GAME, { gamePk: gamePk });
    updateRouteParams(
      navigate,
      url,
      {
        statusId: statusId,
        completeId: completeId,
        categoryId: categoryId,
        search: search,
      },
      { previousLocation: location.state.previousLocation }
    );
  };

  const updateValue = (key, value) => {
    updateUrl({
      statusId,
      categoryId,
      completeId,
      search,
      [key]: value,
    });
  };

  const close = () => {
    if (location.state.previousLocation) {
      navigate(location.state.previousLocation);
    } else {
      navigate(RouteConstants.ADMIN.REQUESTS);
    }
  };

  const fetchGames = async () => {
    try {
      setLoading(true);
      const resp = await getRequestsByGamePk(gamePk, statusId, categoryId, completeId);
      setData({
        game: resp.games[gamePk],
        requests: resp.requests[gamePk],
        totalRequests: resp.totalRequests[gamePk],
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchGames();
  }, [gamePk, statusId, categoryId, completeId]);

  useEffect(() => {
    setFilteredData(requestsFreeTextSearch(data.requests, search));
  }, [data, search]);

  return (
    <Card>
      <Card.Header>Ticket Requests</Card.Header>
      <Card.Body>
        <Row className="mt-2">
          <Column width={4}>
            <Select
              id="status"
              label="Status"
              value={status}
              options={requestStateOptions}
              onChange={(value: ValueOpt<RequestState>) => updateValue("statusId", value.value.requestStateId)}
              placeholder="select a status"
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Select
              id="complete"
              label="Complete/Incomplete"
              value={complete}
              options={completeOptions}
              onChange={(value: ValueOpt<ReportComplete>) => updateValue("completeId", value.value.id)}
              placeholder="select a complete status"
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Select
              id="category"
              label="Category"
              value={category}
              options={requestCategoryOptions}
              onChange={(value: ValueOpt<RequestCategory>) => updateValue("categoryId", value.value.requestCategoryId)}
              placeholder="select a category"
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Search
              id="search"
              label="Search Requests"
              value={search}
              onChange={(value) => updateValue("search", value)}
              placeholder="begin typing to filter requests"
              gutterBottom
            />
          </Column>
        </Row>
        <div>
          {data.game && <AdminRequestGame {...data} requests={filteredData} showHeader={true} isVisible={true} />}
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="default" onClick={close}>
            Close
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminRequestByGame;
