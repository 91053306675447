import {
  Button,
  Card,
  Checkbox,
  Column,
  Input,
  Row,
  StickyFooterButtons,
  SubSection,
  Textarea,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { graphQl, saveProject } from "../../../api/RequesTixApi";
import UserSearch from "../../../components/user/search/UserSearch";
import RouteConstants from "../../../constants/RouteConstants";
import { ProjectDTO } from "../../../types/Project";
import { User } from "../../../types/User";

const createQuery = (projectId: number | string) => `{
  getProject(projectId: ${projectId}) {
    projectId,
    name,
    description,
    active,
    users {
      employeeUserId,
    }
  }
}
`;

const isNewProject = (projectId: number | string) => parseInt(projectId as string) === -1;

type AdminEditProjectUrlProps = {
  projectId: string;
};

const AdminEditProject = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [canSave, setCanSave] = useState(false);
  const [users, setUsers] = useState<ValueOpt<User>[]>([]);
  const { projectId } = useParams<AdminEditProjectUrlProps>();
  const [project, setProject] = useState<ProjectDTO>({
    projectId: null,
    name: "",
    description: "",
    active: true,
    userIds: [],
  });

  const fetchProject = async (projectId: number | string) => {
    try {
      setLoading(true);
      const queryObj = {
        query: createQuery(projectId),
        variables: null,
        operationName: null,
      };
      const data = await graphQl(queryObj);
      const res = data.getProject;
      const d = {
        projectId: res.projectId,
        name: res.name,
        description: res.description,
        active: res.active,
        userIds: res.users ? res.users.map((u) => u.employeeUserId) : [],
      };
      setProject(d);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key: keyof ProjectDTO, value: boolean | string | number[]) => {
    setProject({ ...project, [key]: value });
  };

  const cancel = () => {
    navigate(RouteConstants.ADMIN.PROJECTS);
  };

  const save = async () => {
    try {
      setLoading(true);
      await saveProject(project);
      navigate(RouteConstants.ADMIN.PROJECTS);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNewProject(projectId)) {
      void fetchProject(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    const userIds = users ? users.map((user: ValueOpt<User>) => user.value.employeeUserId) : [];
    onChange("userIds", userIds);
  }, [users]);

  useEffect(() => {
    if (project.name.length) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [project]);

  return (
    <Card>
      <Card.Header>Project</Card.Header>
      <Card.Body>
        <Row>
          <Column width={2}>
            <SubSection header="Project Details">
              <Row>
                <Column width={2}>
                  <Input
                    id="project"
                    label="Project"
                    required
                    gutterBottom
                    placeholder="enter a project name"
                    value={project.name}
                    onChange={(value) => onChange("name", value)}
                  />
                </Column>
                <Column width={2}>
                  <Checkbox
                    id="project-active"
                    className="mt-4"
                    label="Active"
                    name="active"
                    checked={project.active}
                    onChange={() => {
                      onChange("active", !project.active);
                    }}
                    gutterBottom
                  />
                </Column>
                <Column width={2}>
                  <Textarea
                    id="project-description"
                    label="Description"
                    value={project.description}
                    onChange={(value: string) => {
                      onChange("description", value);
                    }}
                    gutterBottom
                  />
                </Column>
              </Row>
            </SubSection>
          </Column>
          <Column width={2}>
            <SubSection header="Project Access">
              <UserSearch
                id="users"
                label="Users"
                isMulti={true}
                value={users}
                onChange={(value: ValueOpt<User>[]) => setUsers(value)}
                existingUserIds={project.userIds}
              />
            </SubSection>
          </Column>
        </Row>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="primary" disabled={!canSave} onClick={save}>
            Save
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminEditProject;
