import { Attribute, Card, Column, Row, SubSection, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getUser } from "../../api/RequesTixApi";
import UserBilling from "../../components/payment/UserBilling";
import { useAuth } from "../../contexts/AuthContext";
import { UserDTO } from "../../types/User";
import UserAllotment from "./UserAllotment";

const Account = () => {
  const { setLoading } = useLoading();
  const { userInfo } = useAuth();
  const [user, setUser] = useState<UserDTO>();

  const goGetUser = async () => {
    try {
      setLoading(true);
      const res: UserDTO = await getUser(userInfo.employeeUserId);
      setUser(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!userInfo.employeeUserId) {
      void goGetUser();
    }
  }, [userInfo]);

  return (
    <Card>
      <Card.Header>My Account</Card.Header>
      <Card.Body>
        <Row>
          <Column width={2}>
            <SubSection header="Account Information">
              <Row>
                <Column width={2}>
                  <Attribute header="First Name" value={user?.firstName} />
                </Column>
                <Column width={2}>
                  <Attribute header="Last Name" value={user?.lastName} />
                </Column>
                <Column width={2}>
                  <Attribute header="Department" value={user?.department} />
                </Column>
                <Column width={2}>
                  <Attribute header="Title" value={user?.title} />
                </Column>
                <Column width={2}>
                  <Attribute header="Email" value={user?.email} />
                </Column>
              </Row>
            </SubSection>
          </Column>
          <Column width={2}>
            <SubSection header="Requests">
              {userInfo?.employeeUserId ? <UserAllotment userId={userInfo.employeeUserId} /> : null}
            </SubSection>
          </Column>
        </Row>
        <SubSection header="Credit Cards">
          <UserBilling billings={user?.billings} userId={userInfo?.employeeUserId} refresh={goGetUser} />
        </SubSection>
      </Card.Body>
    </Card>
  );
};

export default Account;
