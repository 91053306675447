import { Attribute, Card, Column, Row, Select, SubSection, useLoading, ValueOpt } from "best-common-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, updateUser } from "../../../api/RequesTixApi";
import AdminFooter from "../../../components/admin/AdminFooter";
import UserBilling from "../../../components/payment/UserBilling";
import RouteConstants from "../../../constants/RouteConstants";
import UserAllotmentConstants from "../../../constants/UserAllotmentConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { Role } from "../../../types/Role";
import { UserDTO } from "../../../types/User";
import UserAllotment from "../../user/UserAllotment";

type AdminEditUserUrlProps = {
  userId: string;
};

const AdminEditUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams<AdminEditUserUrlProps>();
  const { rolesOptions } = useDropdowns();
  const { setLoading } = useLoading();
  const [hasEdited, setHasEdited] = React.useState(false);
  const [user, setUser] = React.useState<UserDTO>({
    employeeUserId: -1,
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    department: "",
    role: "",
    allotments: [],
    billings: [],
  });

  const goGetUser = async () => {
    try {
      const res: UserDTO = await getUser(userId);
      setUser(res);
    } catch (e) {
      console.error(e);
    }
  };

  const updateAllotments = (allotment) => {
    setUser({
      ...user,
      allotments: user.allotments.map((allot) =>
        allot.userAllotmentId === allotment.userAllotmentId ? allotment : allot
      ),
    });
    setHasEdited(true);
  };

  const updateRole = (value) => {
    setUser({ ...user, role: value });
    setHasEdited(true);
  };

  const goBack = () => {
    navigate(RouteConstants.ADMIN.USERS);
  };

  const onSave = () => {
    setLoading(true);
    updateUser(user).then(() => {
      setLoading(false);
      goBack();
    });
  };

  const changeAllotment = (season, year, key, type, value) => {
    const allotmentTypeId = type === UserAllotmentConstants.PERSONAL ? 1 : 2;
    const currentAllotment = user.allotments.find(
      (allot) =>
        allot.allotmentType.allotmentTypeId === allotmentTypeId &&
        allot.season.seasonType.seasonTypeId === season &&
        allot.season.year === year
    );
    if (key === "amount") {
      //@ts-ignore
      currentAllotment.value = value && value.length ? parseInt(value) : "";
    } else {
      currentAllotment.unlimited = !!value;
    }
    updateAllotments(currentAllotment);
  };

  React.useEffect(() => {
    if (userId) {
      goGetUser();
    }
  }, [userId]);

  return (
    <Card>
      <Card.Header>User</Card.Header>
      <Card.Body>
        <Row>
          <Column width={2}>
            <SubSection header="Account Information">
              <Row>
                <Column width={2}>
                  <Attribute header="First Name" value={user.firstName} />
                </Column>
                <Column width={2}>
                  <Attribute header="Last Name" value={user.lastName} />
                </Column>
                <Column width={2}>
                  <Attribute header="Department" value={user.department} />
                </Column>
                <Column width={2}>
                  <Attribute header="Title" value={user.title} />
                </Column>
                <Column width={2}>
                  <Attribute header="Email" value={user.email} />
                </Column>
                <Column width={2}>
                  <Select
                    id="roles-dropdown"
                    label="Role"
                    options={rolesOptions}
                    value={rolesOptions.find((role) => role.label === user.role) || []}
                    onChange={(value: ValueOpt<Role>) => updateRole(value.label)}
                  />
                </Column>
              </Row>
            </SubSection>
          </Column>
          <Column width={2}>
            <SubSection header="Requests">
              {user.allotments ? (
                <UserAllotment
                  userId={userId}
                  editable={true}
                  onChange={changeAllotment}
                  allotments={user.allotments}
                />
              ) : null}
            </SubSection>
          </Column>
        </Row>
        <Row>
          <Column width={1}>
            <SubSection header="Credit Cards">
              <UserBilling billings={user.billings} userId={userId} refresh={goGetUser} />
            </SubSection>
          </Column>
        </Row>
      </Card.Body>
      <Card.Footer>
        <AdminFooter hasEdited={hasEdited} onCancel={goBack} onSave={onSave} />
      </Card.Footer>
    </Card>
  );
};

export default AdminEditUser;
