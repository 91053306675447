import { datadogRum } from "@datadog/browser-rum";
import { AlertsFromProvider, LoadingFromProvider } from "best-common-react";
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Navigation from "./components/navigation/Navigation";
import RouteConstants from "./constants/RouteConstants";
import AdminDepartmentsPage from "./containers/admin/department/AdminDepartments";
import AdminEditDepartments from "./containers/admin/department/AdminEditDepartments";
import AdminDigitalTickets from "./containers/admin/digitalTickets/AdminDigitalTickets";
import AdminEditEventsPage from "./containers/admin/event/AdminEditEvent";
import AdminEventsPage from "./containers/admin/event/AdminEvents";
import AdminEditProjectsPage from "./containers/admin/project/AdminEditProject";
import AdminProjectsPage from "./containers/admin/project/AdminProject";
import AdminPurchaseOpportunity from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunity";
import AdminPurchaseOpportunityForm from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunityForm";
import AdminPurchaseOpportunityView from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunityView";
import AdminEditRequestPage from "./containers/admin/request/AdminEditRequest";
import AdminRequestsByGamePage from "./containers/admin/request/AdminRequestByGame";
import AdminRequestBySeriesPage from "./containers/admin/request/AdminRequestBySeries";
import AdminRequestsPage from "./containers/admin/request/AdminRequests";
import AdminSuiteRequestEdit from "./containers/admin/suites/AdminSuiteRequestEdit";
import AdminSuiteRequests from "./containers/admin/suites/AdminSuiteRequests";
import AdminDayOfPage from "./containers/admin/todaysTickets/AdminTodaysTickets";
import AdminTodaysTicketsEdit from "./containers/admin/todaysTickets/AdminTodaysTicketsEdit";
import AdminEditUserPage from "./containers/admin/user/AdminEditUser";
import AdminUsersPage from "./containers/admin/user/AdminUsers";
import AdminEditVenuePage from "./containers/admin/venue/AdminEditVenue";
import AdminVenuesPage from "./containers/admin/venue/AdminVenues";
import HomePage from "./containers/Home";
import MyPurchaseOpportunityRequest from "./containers/purchaseOpportunity/MyPurchaseOpportunityRequest";
import PurchaseOpportunityList from "./containers/purchaseOpportunity/PurchaseOpportunityList";
import PurchaseOpportunityRequest from "./containers/purchaseOpportunity/PurchaseOpportunityRequest";
import PurchaseOpportunityRequestEdit from "./containers/purchaseOpportunity/PurchaseOpportunityRequestEdit";
import ReportsPage from "./containers/Reports";
import MyRequestsPage from "./containers/request/MyRequests";
import EditSuiteRequestPage from "./containers/request/suite/EditSuiteRequest";
import MySuiteRequestsPage from "./containers/request/suite/MySuiteRequests";
import NewSuiteRequestPage from "./containers/request/suite/NewSuiteRequest";
import TicketRequestPage from "./containers/request/TicketRequest";
import TicketRequestEditPage from "./containers/request/TicketRequestEdit";
import DayOf from "./containers/TodaysTicketsContainer";
import AccountPage from "./containers/user/Account";
import Providers from "./contexts";
import { useAuth } from "./contexts/AuthContext";
import { useEnv } from "./contexts/EnvContext";
import ScrollToTop from "./util/ScrollToTop";
import styled from "styled-components";

const Content = styled.div`
  padding: 1rem;
`;

const AuthenticatedApp = () => {
  const { loggedIn, isAdmin } = useAuth();
  const {
    variables: { ENV, DATADOG_APP_ID, DATADOG_CLIENT_TOKEN },
  } = useEnv();

  useEffect(() => {
    datadogRum.init({
      applicationId: DATADOG_APP_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "us5.datadoghq.com",
      service: "requestix",
      env: ENV,
      version: "0.0.1",
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  return (
    <>
      {loggedIn && (
        <Providers>
          <LoadingFromProvider />
          <AlertsFromProvider />
          <>
            <ScrollToTop />
            <Navigation />
            <Content>
              <Routes>
                <Route path={RouteConstants.BASE} element={<HomePage />} />
                <Route path={RouteConstants.LOGIN} element={<HomePage />} />
                <Route path={RouteConstants.TODAYS_TICKETS} element={<DayOf />} />
                <Route path={RouteConstants.REQUESTS.BASE} element={<MyRequestsPage />} />
                <Route path={RouteConstants.REQUESTS.TICKETS} element={<TicketRequestPage />} />
                <Route path={RouteConstants.REQUESTS.SUITES} element={<MySuiteRequestsPage />} />
                <Route path={RouteConstants.REQUESTS.SUITES_REQUEST} element={<NewSuiteRequestPage />} />
                <Route path={RouteConstants.REQUESTS.SUITES_REQUEST_EDIT} element={<EditSuiteRequestPage />} />
                <Route path={RouteConstants.REQUESTS.EDIT} element={<TicketRequestEditPage />} />
                <Route path={RouteConstants.REPORTS} element={<ReportsPage />} />
                <Route path={RouteConstants.PURCHASE_OPPORTUNITY.LIST} element={<PurchaseOpportunityList />} />
                <Route path={RouteConstants.PURCHASE_OPPORTUNITY.REQUESTS} element={<MyPurchaseOpportunityRequest />} />
                <Route path={RouteConstants.PURCHASE_OPPORTUNITY.REQUEST} element={<PurchaseOpportunityRequest />} />
                <Route
                  path={RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT}
                  element={<PurchaseOpportunityRequestEdit />}
                />
                <Route path={RouteConstants.USER.ACCOUNT} element={<AccountPage />} />
                {isAdmin && (
                  <>
                    <Route path={RouteConstants.ADMIN.REQUESTS} element={<AdminRequestsPage />} />
                    <Route path={RouteConstants.ADMIN.REQUESTS_GAME} element={<AdminRequestsByGamePage />} />
                    <Route path={RouteConstants.ADMIN.REQUESTS_SERIES} element={<AdminRequestBySeriesPage />} />
                    <Route path={RouteConstants.ADMIN.REQUESTS_EDIT} element={<AdminEditRequestPage />} />
                    <Route path={RouteConstants.ADMIN.USERS_EDIT} element={<AdminEditUserPage />} />
                    <Route path={RouteConstants.ADMIN.USERS} element={<AdminUsersPage />} />
                    <Route path={RouteConstants.ADMIN.DEPARTMENTS_EDIT} element={<AdminEditDepartments />} />
                    <Route path={RouteConstants.ADMIN.DEPARTMENTS} element={<AdminDepartmentsPage />} />
                    <Route path={RouteConstants.ADMIN.VENUES_EDIT} element={<AdminEditVenuePage />} />
                    <Route path={RouteConstants.ADMIN.VENUES} element={<AdminVenuesPage />} />
                    <Route path={RouteConstants.ADMIN.EVENTS_EDIT} element={<AdminEditEventsPage />} />
                    <Route path={RouteConstants.ADMIN.EVENTS} element={<AdminEventsPage />} />
                    <Route path={RouteConstants.ADMIN.PROJECTS} element={<AdminProjectsPage />} />
                    <Route path={RouteConstants.ADMIN.PROJECTS_EDIT} element={<AdminEditProjectsPage />} />
                    <Route path={RouteConstants.ADMIN.TODAYS_TICKETS} element={<AdminDayOfPage />} />
                    <Route path={RouteConstants.ADMIN.TODAYS_TICKETS_EDIT} element={<AdminTodaysTicketsEdit />} />
                    <Route path={RouteConstants.ADMIN.DIGITAL_TICKETS} element={<AdminDigitalTickets />} />
                    <Route
                      path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE}
                      element={<AdminPurchaseOpportunity />}
                    />
                    <Route
                      path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.EDIT}
                      element={<AdminPurchaseOpportunityForm />}
                    />
                    <Route
                      path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.VIEW}
                      element={<AdminPurchaseOpportunityView />}
                    />
                    <Route path={RouteConstants.ADMIN.SUITE_REQUESTS.BASE} element={<AdminSuiteRequests />} />
                    <Route path={RouteConstants.ADMIN.SUITE_REQUESTS.EDIT} element={<AdminSuiteRequestEdit />} />
                  </>
                )}
                <Route path={RouteConstants.WILDCARD} element={<Navigate to={RouteConstants.BASE} replace />} />
              </Routes>
            </Content>
          </>
        </Providers>
      )}
    </>
  );
};

export default AuthenticatedApp;
