import { Column, Row, Select } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getUserAllotmentCounts } from "../../api/RequesTixApi";
import UserAllotmentDisplay from "../../components/user/UserAllotmentDisplay";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";
import { useAdminYear } from "../../contexts/AdminYearContext";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { useMetadata } from "../../contexts/MetadataContext";

type UserAllotmentProps = {
  userId: number | string;
  editable?: boolean;
  onChange?: (id: number, year: number, key: string, type: string, value: number | boolean) => void;
  allotments?: any;
};

const UserAllotment = ({ userId, editable = false, onChange = () => {}, allotments }: UserAllotmentProps) => {
  const { activeSeason } = useMetadata();
  const { yearsOptions, getOptionByValue } = useDropdowns();
  const [selectedYear, setSelectedYear] = useAdminYear({
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear(),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [springAllotment, setSpringAllotment] = useState({});
  const [regularAllotment, setRegularAllotment] = useState({});
  const [userAllotments, setUserAllotments] = useState([]);
  const [finalAllotments, setFinalAllotments] = useState([]);

  const fetchUserAllotments = async (userId: number | string, year: number) => {
    try {
      const data = await getUserAllotmentCounts(userId, year);
      setUserAllotments(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeSeason && yearsOptions && yearsOptions.length) {
      const defaultSelectedYear = getOptionByValue(yearsOptions, activeSeason.year);
      if (defaultSelectedYear) {
        setSelectedYear(defaultSelectedYear);
      }
    }
  }, [activeSeason, yearsOptions]);

  useEffect(() => {
    if (userId && selectedYear.value) {
      void fetchUserAllotments(userId, selectedYear.value);
    }
  }, [selectedYear, userId]);

  useEffect(() => {
    if (userAllotments.length) {
      if (allotments) {
        setFinalAllotments(
          userAllotments.map((allot) => {
            const override = allotments.find((a) => {
              return (
                a.allotmentType.allotmentTypeId === allot.allotmentType.allotmentTypeId &&
                a.season.seasonType.seasonTypeId === allot.season.seasonType.seasonTypeId &&
                a.season.year === selectedYear.value
              );
            });
            if (override) {
              return { ...allot, unlimited: override.unlimited, allowed: override.value };
            } else {
              return allot;
            }
          })
        );
      } else {
        setFinalAllotments(userAllotments);
      }
    }
  }, [userAllotments, allotments, selectedYear]);

  useEffect(() => {
    const personal = finalAllotments.filter(
      (allotment) => allotment.allotmentType.allotmentTypeId === UserAllotmentConstants.PERSONAL_ID
    );
    const business = finalAllotments.filter(
      (allotment) => allotment.allotmentType.allotmentTypeId === UserAllotmentConstants.BUSINESS_ID
    );

    const springTraining = {
      personal: personal.find(
        (allotment) => allotment.season.seasonType.seasonTypeId === 1 && allotment.season.year === selectedYear.value
      ),
      business: business.find(
        (allotment) => allotment.season.seasonType.seasonTypeId === 1 && allotment.season.year === selectedYear.value
      ),
    };
    const regularSeason = {
      personal: personal.find(
        (allotment) => allotment.season.seasonType.seasonTypeId === 2 && allotment.season.year === selectedYear.value
      ),
      business: business.find(
        (allotment) => allotment.season.seasonType.seasonTypeId === 2 && allotment.season.year === selectedYear.value
      ),
    };
    setSpringAllotment(springTraining);
    setRegularAllotment(regularSeason);
  }, [finalAllotments, selectedYear]);

  return (
    <Row>
      <Column width={2}>
        <Select
          id="years-dropdown"
          label="Year"
          options={yearsOptions}
          value={selectedYear}
          onChange={setSelectedYear}
        />
      </Column>
      <Column>
        <UserAllotmentDisplay
          season="Spring Training"
          allotments={springAllotment}
          editable={editable}
          onChange={(key, type, value) => onChange(1, selectedYear.value, key, type, value)}
          loading={loading}
        />
      </Column>
      <Column>
        <UserAllotmentDisplay
          season="Regular Season"
          allotments={regularAllotment}
          editable={editable}
          onChange={(key, type, value) => onChange(2, selectedYear.value, key, type, value)}
          loading={loading}
        />
      </Column>
    </Row>
  );
};

export default UserAllotment;
